/* --------------------------------------------------------------------------------------------------- */
/* VIDEO --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.wrapper_video {
	@extend .relative;
	padding-bottom:56.25%;
	height:0;
	background:$color_theme_01-02;
	overflow:hidden;

	video,
	iframe {
		position:absolute;
		top:0; left:0;
		z-index:1;
		width:100%; height:100%;
	}
}
