/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (RESET) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
button,
input,
optgroup,
select,
textarea {
	margin:0;
	line-height:$line_height_base;
	font-family:$font_family_01;
	font-size:100%;
	border:0 solid;
	border-radius:0;
	appearance:none;
}

[type=tel],
[type=text],
[type=email],
[type=password] {
	display:block;
	width:100%;
	background:transparent;
}

button,
input {
	overflow:visible;
}

button,
select {
	text-transform:none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	appearance:none;
}

[type=submit] {
	width:100%;
	cursor:pointer;
	overflow:visible;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style:none;
	padding:0;
}

fieldset {
	margin:0; padding:0;
	border:none;
}

progress {
	display:inline-block;
	vertical-align:baseline;
}

textarea {
	display:block;
	width:100%;
	background:transparent;
	resize:none;
	overflow:auto;
	box-sizing:border-box;
}

[type="checkbox"],
[type="radio"] {
	padding:0;
	box-sizing:border-box;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset:-2px;
	appearance:textfield;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	appearance:none;
}

::-webkit-file-upload-button {
	font:inherit;
	-webkit-appearance:button;
}

[hidden] {
	display:none;
}

select::-ms-expand {
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity:0;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* STYLE ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form {

	/* --- Label --- */
	label {

		&.field_label {
			display:flex;
			flex-direction:row;
			align-items:flex-end;
		}

		&.in_field_label {
			@extend .animate;
			@extend .full_size;
			box-sizing:border-box;
			cursor:pointer;
		}

		em {
			flex:1;
			padding-left:12px;
			font-size:$font_size_small;
			text-align:right;
			opacity:.48;
		}
	}

	/* --- Input text --- */
	.input_text {
		@extend .animate;
		@extend .relative;
	}

	/* --- Field select --- */
	.field_select {
		@extend .relative;

		select {
			position:relative;
			z-index:2;
			width:100%;
			background:none;
			border:none;
			appearance:none;
			cursor:pointer;

			@media screen and (min-width:769px)
			{
				&:focus + .chevron {
					transform:rotate(270deg);
				}
			}
		}

		.chevron {
			@extend .animate;
			@extend .absolute;
			top:50%;
			transform:rotate(90deg);

			svg {
				display:block;
			}
		}
	}

	/* --- Radio & Checkbox --- */
	.input_radio,
	.input_checkbox {
		@extend .relative;
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;

		input[type=radio],
		input[type=checkbox] {
			position:absolute;
			top:0; left:0;
			z-index:1;
			opacity:0;
			cursor:pointer;
		}
		span {
			@extend .animate;
			display:block;
			text-align:center;
			box-sizing:border-box;
		}
		label {
			flex:1;
			flex-direction:column;
			margin:0; padding:0;
			line-height:$line_height_small;
			cursor:pointer;
		}

		input[type=radio]:checked + span:after,
		input[type=checkbox]:checked + span svg { opacity:1; }
	}

	.input_radio span,
	.input_radio span:after { border-radius:100%; }
	.input_radio span:after {
		content:"";
	}
	.input_radio span:after,
	.input_checkbox span svg {
		@extend .animate;
		display:block;
		opacity:0;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* VALIDATION ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form_alert {
	margin-top:-1px; padding-bottom:2px;
	text-align:center;

	&.is-valid {
		color:$color_valid;
	}
	&.is-invalid {
		color:$color_error;
	}
}

/* --- Feedback --- */
.field-feedback {
	@extend .animate;
	max-height:0;
	overflow:hidden;
	opacity:0;
}

/* --- Badges --- */
.badge_valid,
.badge_invalid {
	@extend .animate;
	position:absolute;
	top:0; right:24px; bottom:0;
	z-index:2;
	width:16px;
	opacity:0;

	svg {
		display:block;
		margin-top:24px;
		width:16px; height:16px;
	}
}
.badge_valid svg {
	fill:$color_valid;
}
.badge_invalid svg {
	fill:$color_error;
}

.input_radio .badge_valid,
.input_radio .badge_invalid,
.input_checkbox .badge_valid,
.input_checkbox .badge_invalid {
	top:0; left:25px; right:auto;
	width:12px; height:12px;
	transform:translateY(-50%);

	svg {
		display:none;
	}
}

/* --- Valid --- */
.is-valid {

	.field-feedback,
	.icon.badge_invalid {
		opacity:0;
	}
	.icon.badge_valid {
		opacity:1;
	}
}

/* --- Invalid --- */
.is-invalid {

	.icon.badge_valid {
		opacity:0;
	}
	.field-feedback,
	.icon.badge_invalid {
		opacity:1;
	}

	.field-feedback {
		max-height:none;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.no-js {

	/* --- Select --- */
	.field_select {

		span {
			display:none;
		}

		select {
			position:static;
			padding:16px 20px 17px;
			border:2px solid rgba($color_theme_01-01, .12);
			opacity:1;
		}
	}
}
