/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN NAVIGATION (1ST LEVEL) -------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main-navigation {
	@extend .flex_column;
	position:relative;
	z-index:3;

	@media screen and (min-width:769px)
	{
		flex:1 1 auto;
		align-items:flex-end;
		padding-left:32px;
	}
	@media screen and (max-width:768px)
	{
		order:1;
		width:33%;
	}
}

	.main-navigation_list {
		@extend .animate;
		display:flex;
		flex-wrap:nowrap;
		list-style:none;
		font-family:$font_family_02;

		@media screen and (min-width:769px)
		{
			flex-direction:row;
		}
		@media screen and (max-width:768px)
		{
			flex-direction:column;
			position:fixed;
			top:50px; left:0; bottom:0;
			z-index:99999;
			padding:24px;
			width:100vw;
			background:#FFF;
			border-top:1px solid $color_theme_01-02;
			box-sizing:border-box;
			overflow:auto;
			transform:translate(-100%);
		}
	}
		.main-navigation_list-item {

			@media screen and (max-width:920px) and (min-width:769px)
			{
				font-size:16px;
			}

			&:not(:first-child) {

				@media screen and (min-width:921px)
				{
					padding-left:32px;
				}
				@media screen and (max-width:920px) and (min-width:769px)
				{
					padding-left:24px;
				}
				@media screen and (max-width:768px)
				{
					padding-top:18px;
				}
			}

			&.current-menu-item .main-navigation_list-item_link,
			&.current-menu-parent .main-navigation_list-item_link {
				color:$color_theme_01-01;
				font-weight:700;
			}
			&.current-menu-item .main-navigation_list-item_link {
				cursor:default;
			}
		}
			.main-navigation_list-item_link {
				@extend .animate;
				color:$color_theme_01-03;

				@media screen and (min-width:921px)
				{
					text-transform:uppercase;
				}
				@media screen and (min-width:769px)
				{
					font-size:$font_size_small;

					&:hover,
					&:focus {
						color:$color_theme_01-01;
					}

					&:focus + .main-navigation_submenu {
						opacity:1;
						visibility:visible;
					}
				}
				@media screen and (max-width:768px)
				{
					font-size:$font_size_base;
				}
			}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN NAVIGATION (2ND LEVEL) -------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main-navigation_submenu {
	@extend .animate;
	list-style:none;

	@media screen and (min-width:769px)
	{
		position:absolute;
		top:56px;
		z-index:2;
		margin-left:-24px;
		max-width:300px;
		opacity:0;
		visibility:hidden;
	}
	@media screen and (max-width:768px)
	{
		padding-top:9px;
	}

	&:before {
		@extend .absolute;

		@media screen and (min-width:769px)
		{
			content:"";
			top:-6px; left:24px;
			width:0; height:0;
			border-left:6px solid transparent; border-right:6px solid transparent; border-bottom:6px solid darken($color_theme_01-02, 6%);
		}
	}
}
	.main-navigation_submenu-item {

		&:not(:first-child) {

			@media screen and (min-width:769px)
			{
				border-top:1px solid darken($color_theme_01-02, 12%);
			}
		}

		&.current-menu-item .main-navigation_submenu-item_link,
		&.current-charity-ancestor .main-navigation_submenu-item_link {
			color:$color_theme_01-01;
		}
		&.current-menu-item .main-navigation_submenu-item_link {
			cursor:default;
		}
	}
		.main-navigation_submenu-item_link {
			@extend .animate;
			display:block;
			color:$color_theme_01-03;
			font-family:$font_family_01;
			font-size:16px;

			@media screen and (min-width:769px)
			{
				padding:16px 24px;
				background:darken($color_theme_01-02, 6%);

				&:hover,
				&:focus {
					color:$color_theme_01-01;
				}
			}
		}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MOBILE MAIN NAVIGATION (TRIGGER) --------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main-navigation_trigger {
	display:none;

	button {
		@extend .animate;
		@extend .relative;
		background:transparent;

		span {
			@extend .animate;
			@extend .relative;

			&:before,
			&:after {
				@extend .animate;
				@extend .absolute;
			}
		}
	}

	@media screen and (max-width:768px)
	{
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
		flex:1 1 auto;
		justify-content:center;
		align-items:flex-start;
		padding:0 24px;
		border-right:1px solid $color_theme_01-02;

		button {
			padding-left:30px;

			span {
				display:block;
				position:absolute;
				top:0; left:0;
				z-index:1;
				margin:10px 2px;
				width:18px; height:2px;
				background:$color_theme_01-01;

				&:before,
				&:after {
					content:"";
					left:0;
					width:18px; height:2px;
					background:$color_theme_01-01;
				}
				&:before { top:-6px; }
				&:after { top:6px; }
			}

			&.open {

				span {
					background:rgba(#FFF, 0);

					&:before {
						top:0;
						transform:rotate(45deg);
					}
					&:after {
						top:0;
						transform:rotate(-45deg);
					}
				}
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MOBILE MAIN NAVIGATION (OPEN) ------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js_main-navigation_open {
	overflow:hidden;

	.main-navigation_list {

		@media screen and (max-width:768px)
		{
			transform:translateX(0);
		}
	}

	.main-navigation_trigger button span {

		@media screen and (max-width:768px)
		{
			background:rgba(#FFF, 0);

			&:before {
				top:0;
				transform:rotate(45deg);
			}
			&:after {
				top:0;
				transform:rotate(-45deg);
			}
		}
	}
}
