/* ------------------------------------------------------------------------------------------------------------------------ */
/* TWITTER FEED ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.twitter-feed {
	padding:96px;
	background:$color_theme_02-01;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding:48px;
	}
	@media screen and (max-width:768px)
	{
		padding:24px;
	}

	.grid {

		@media screen and (max-width:910px) and (min-width:769px)
		{
			.column.width_03-12 {
				width:33%;
			}
			.column.width_09-12 {
				width:67%;
			}
		}
	}

	.slick-list {

		@media screen and (min-width:769px)
		{
			margin-left:-6px; margin-right:-6px;
		}

		.slick-track {
			margin:0;

			.slick-slide {

				@media screen and (min-width:769px)
				{
					padding-left:6px; padding-right:6px;
				}
			}
		}
	}
}

	.twitter-feed_heading {
		padding-right:36px;
		color:#FFF;

		@media screen and (max-width:768px)
		{
			padding:0 0 24px;
		}
	}
		.twitter-feed_preheading {
			display:block;
			color:rgba(#FFF, .64);
			font-size:$font_size_base;
			font-weight:400;

			@media screen and (min-width:769px)
			{
				padding-bottom:6px;
			}
		}
	.twitter-feed_btn {
		padding-top:24px;

		@media screen and (max-width:768px)
		{
			display:none;
		}
	}

	.twitter-feed_carousel {

		@media screen and (max-width:768px)
		{
			margin:0 -24px;
			width:calc(100% + 48px) !important;
		}

		.slick-slide {

			@media screen and (max-width:768px)
			{
				padding:0 24px;
			}
		}
	}

	.twitter-feed_carousel-controls {
		display:none;

		@media screen and (max-width:768px)
		{
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			justify-content:space-between;
			align-items:center;
			padding-top:24px;
			width:100%;
		}
	}
		.twitter-feed_carousel-controls_prev svg,
		.twitter-feed_carousel-controls_next svg {

			@media screen and (max-width:768px)
			{
				fill:#FFF;
			}
		}

	.twitter-feed_item {
		@extend .relative;
		text-align:center;
		box-sizing:border-box;
	}
		.twitter-feed_item-icon {
			margin:0 auto;
			width:32px; height:32px;

			@media screen and (max-width:768px)
			{
				position:absolute;
				right:9px; bottom:9px;
				z-index:1;
				width:18px; height:18px;
			}

			svg {
				display:block;
				width:32px; height:32px;
				fill:$color_twitter;

				@media screen and (max-width:768px)
				{
					width:18px; height:18px;
					fill:$color_theme_01-04;
				}
			}
		}
		.twitter-feed_item-content {
			line-height:$line_height_small;
			font-style:italic;

			@media screen and (min-width:769px)
			{
				padding-top:24px;
			}
		}
		.twitter-feed_item-link {
			margin-bottom:-16px; padding-top:32px;
			font-size:$font_size_small;

			@media screen and (max-width:768px)
			{
				padding-top:16px;
			}

			a {
				@extend .animate;
				opacity:.48;

				@media screen and (min-width:769px)
				{
					&:hover,
					&:focus {
						color:$color_twitter;
						opacity:1;
					}
				}
			}
		}
