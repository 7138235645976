/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin:0;
	font-weight:700;
	font-family:$font_family_02;
}
h1, .h1 {
	line-height:1.2;
	font-size:48px;

	@media screen and (max-width:768px)
	{
		font-size:32px;
	}
}
h2, .h2 {
	line-height:1.3;
	font-size:32px;

	@media screen and (max-width:768px)
	{
		font-size:24px;
	}
}
h3, .h3 {
	line-height:1.4;
	font-size:24px;

	@media screen and (max-width:768px)
	{
		font-size:18px;
	}
}
h4, .h4 {
	font-size:18px;
	font-weight:400;

	@media screen and (max-width:768px)
	{
		font-size:$font_size_base;
	}
}
h5, .h5,
h6, .h6 {
	line-height:$line_height_base;
	font-size:$font_size_base;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HR LINES --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
hr {
	height:0;
	overflow:visible;
	box-sizing:content-box;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANCHORS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
a {
	color:$color_theme_01-01;
	text-decoration:none;
	text-decoration-skip:objects;
	background-color:transparent;

	&:active,
	&:hover {
		outline-width:0;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT BOLD -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
b,
strong {
	font-weight:inherit;
	font-weight:bold;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT ITALIC ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
i,
em,
dfn {
	font-style:italic;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT HIGHLIGHTED ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
mark {
	color:#333;
	background:none;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT SMALL ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
small,
.small {
	font-size:$font_size_small;
}

sub,
sup {
	position:relative;
	line-height:0;
	font-size:75%;
	vertical-align:baseline;
}
sub {
	bottom:-0.25em;
}
sup {
	top:-0.5em;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* IMAGES ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
figure {
	display:block;
	margin:0;

	figcaption {
		display:block;
	}
}

img {
	width:auto\9; height:auto;
	vertical-align:middle;
	border:0;
	-ms-interpolation-mode:bicubic;
}

svg:not(:root) {
	overflow:hidden;
}
