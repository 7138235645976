/* ------------------------------------------------------------------------------------------------------------------------ */
/* FASTLINKS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.fastlinks {
	border-bottom:2px solid $color_theme_01-04;
}
	.fastlinks_item {
		padding:32px 96px;

		@media screen and (max-width:1024px) and (min-width:769px)
		{
			padding:24px 48px;
		}
		@media screen and (max-width:768px)
		{
			padding:24px;
		}

		&:not(:first-child) {
			border-top:2px solid $color_theme_01-04;
		}
	}
		.fastlinks_item .center_content {
			@extend .flex_row;
			align-items:center;
		}
			.fastlinks_item-dot {
				flex-shrink:0;
				width:24px; height:24px;
				border-radius:100%;

				@media screen and (max-width:768px)
				{
					display:none;
				}
			}
			.fastlinks_item-content {
				flex:1 1 auto;
				padding:0 32px;
			}
				.fastlinks_item-content_heading {
					color:$color_theme_01-01;

					@media screen and (max-width:1024px) and (min-width:769px)
					{
						font-size:24px;
					}
					@media screen and (max-width:768px)
					{
						padding:0; padding-right:24px;
						font-size:$font_size_base;
					}
				}
				.fastlinks_item-content_excerpt {
					padding-top:12px;
					width:100%; max-width:720px;
				}
			.fastlinks_item-btn {
				flex-shrink:0;

				a {
					@media screen and (max-width:768px)
					{
						padding:0;
					}

					span {

						@media screen and (max-width:768px)
						{
							display:none;
						}
					}
					svg {
						display:none;

						@media screen and (max-width:768px)
						{
							display:block;
							padding:15px;
							width:18px; height:18px;
							fill:#FFF;
						}
					}
				}
			}
