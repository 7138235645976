/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography {
	color:$color_theme_01-03;

	h1, h2, h3, h4, h5, h6, p, ul, ol {
		padding-top:1.5em;

		@media screen and (max-width:768px)
		{
			padding-top:1em;
		}

		&:first-child {
			padding-top:0;
		}
	}

	h1, h2, h3, h4, h5, h6, strong {
		color:$color_theme_01-01;
	}

	ul {
		list-style:none;

		@media screen and (min-width:769px)
		{
			margin-top:-.5em;
		}

		& > li {
			position:relative;
			z-index:1;
			padding-left:24px;
			line-height:$line_height_small;

			&:before {
				content:"";
				position:absolute;
				top:0; left:0;
				z-index:1;
				margin:10px 12px 0 0;
				width:7px; height:7px;
				background:$color_theme_01-01;
				border-radius:100%;

				@media screen and (max-width:768px)
				{
					margin-top:7px;
				}
			}

			& > ul {

				@media screen and (min-width:769px)
				{
					padding:1em 0 .5em !important;
				}
			}
		}
	}

	hr {
		padding:0; margin:1.5em 0 .5em;
		height:1px;
		background-color:$color_theme_01-04;
		border:none;

		&:first-child {
			margin-top:0;
		}
	}

	a:not(.btn):not(.link) {
		@extend .animate;
		@extend .relative;
		text-decoration:none;
		word-break:break-word;
		border-bottom:1px solid $color_theme_01-01;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:darken($color_theme_01-01, 9%);
				border-bottom:1px solid transparent;
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY (INVERTED) -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typo_inverted {
	color:rgba(#FFF, .64);

	h1, h2, h3, h4, h5, h6, strong {
		color:#FFF;
	}

	hr {
		background-color:#FFF;
	}

	a:not(.btn):not(.link) {
		color:#FFF;
		border-bottom:1px solid #FFF;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:#FFF;
				border-bottom:1px solid transparent;
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY (COMPRESSED) ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_compressed {
	line-height:1.5;

	h1, h2, h3, h4, h5, h6, p, ul, ol {
		padding-top:1em;

		@media screen and (max-width:768px)
		{
			padding-top:.75em;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY (BLUE) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_02-01 {

	h1, h2, h3, h4, h5, h6, strong {
		color:$color_theme_02-01;
	}

	ul  > li:before {
		background:$color_theme_02-01;
	}

	a:not(.btn):not(.link) {
		color:$color_theme_02-01;
		border-bottom:1px solid $color_theme_02-01;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:darken($color_theme_02-01, 9%);
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY (PURPLE) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_02-02 {

	h1, h2, h3, h4, h5, h6, strong {
		color:$color_theme_02-02;
	}

	ul  > li:before {
		background:$color_theme_02-02;
	}

	a:not(.btn):not(.link) {
		color:$color_theme_02-02;
		border-bottom:1px solid $color_theme_02-02;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:darken($color_theme_02-02, 9%);
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY (ORANGE) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_02-03 {

	h1, h2, h3, h4, h5, h6, strong {
		color:$color_theme_02-03;
	}

	ul  > li:before {
		background:$color_theme_02-03;
	}

	a:not(.btn):not(.link) {
		color:$color_theme_02-03;
		border-bottom:1px solid $color_theme_02-03;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:darken($color_theme_02-03, 9%);
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY (RED) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_02-04 {

	h1, h2, h3, h4, h5, h6, strong {
		color:$color_theme_02-04;
	}

	ul  > li:before {
		background:$color_theme_02-04;
	}

	a:not(.btn):not(.link) {
		color:$color_theme_02-04;
		border-bottom:1px solid $color_theme_02-04;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:darken($color_theme_02-04, 9%);
			}
		}
	}
}
