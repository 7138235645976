/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading {
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPE 1) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading_type-01 {
	font-size:$font_size_small;
	font-weight:700;
	text-transform:uppercase;
}
