/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.header {
	@extend .flex_row;
	position:fixed;
	top:0; left:0; right:0;
	z-index:9999;
	background:#FFF;

	@media screen and (min-width:921px)
	{
		padding:24px;
	}
	@media screen and (max-width:920px) and (min-width:769px)
	{
		padding:18px;
	}
	@media screen and (min-width:769px)
	{
		align-items:center;
	}
	@media screen and (max-width:768px)
	{
		justify-content:space-between;
		min-height:52px;
	}

	&:after {
		@extend .animate;
		@extend .absolute;
		content:"";
		left:0; right:0; bottom:-6px;
		height:6px;
		background:linear-gradient(to bottom, rgba(#000, .06) 0%,rgba(#000, 0) 100%);
		opacity:0;
	}

	&.is-sticky:after {
		opacity:1;
	}

	/*
	.center_content {
		@extend .flex_row;
		width:100%; max-width:calc(#{$site_width_base} + 96px);

		@media screen and (min-width:769px)
		{
			align-items:center;
		}
		@media screen and (max-width:768px)
		{
			position:relative;
			z-index:1;
			justify-content:space-between;
		}
	}
	*/
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo {
	position:relative;
	z-index:3;
	display:block;
	margin-bottom:-38px;
	width:auto; height:98px;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		margin-bottom:0;
		height:48px;
	}
	@media screen and (max-width:768px)
	{
		position:absolute;
		top:50%; left:50%;
		z-index:1;
		width:32px; height:auto;
		transform:translate(-50%, -50%);
	}

	img {
		display:block;
		position:relative;
		z-index:4;
		width:auto; height:98px;

		@media screen and (max-width:1024px) and (min-width:769px)
		{
			height:48px;
		}
		@media screen and (max-width:768px)
		{
			width:32px; height:auto;
		}
	}

	svg {
		display:block;
		position:absolute;
		top:83px; left:50%;
		z-index:3;
		width:792px; height:66px;
		fill:#FFF;
		transform:translateX(-50%);

		@media screen and (max-width:1024px)
		{
			display:none;
		}
	}
}

/*
.logo,
.logo img {



	@media screen and (max-width:920px) and (min-width:769px)
	{
		height:48px;
	}
	@media screen and (max-width:768px)
	{
		width:32px; height:auto;
	}
}
*/

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CTA -------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.header_cta {

	@media screen and (min-width:921px)
	{
		margin-left:32px;
	}
	@media screen and (max-width:920px) and (min-width:769px)
	{
		margin-left:24px;
		font-size:16px;
	}
	@media screen and (max-width:768px)
	{
		order:2;
		padding:16px 24px;
		width:33%;
		text-align:center;
		border-radius:0;
		box-sizing:border-box;
	}
}
