/* BASE */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* THEME COLORS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- PRIMARY --- */
/* --- SECONDARY --- */
/* --- TERTIARY --- */
/* --- SYSTEM COLORS --- */
/* --- SOCIAL COLORS --- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT FAMILIES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT SIZES ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LINE HEIGHTS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAYOUT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FLUID TEXT ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* DOCUMENT --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
* {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline; }

html {
  text-size-adjust: 100%;
  background-color: #EEECE7;
  min-height: 100vh; }

body {
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #727272;
  font-family: "proxima-nova", sans-serif;
  font-size: 18px;
  font-weight: 400;
  cursor: default;
  overflow-x: auto;
  overflow-y: scroll; }
  @media screen and (max-width: 768px) {
    body {
      line-height: 1.4;
      font-size: 14px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SECTIONS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECTIONS ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
::selection {
  color: #FFF;
  background-color: #1F2023; }

::-moz-selection {
  color: #FFF;
  background-color: #1F2023; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* DISPLAY FLEX ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.flex_row, .controls, .pagination_type-01, .pagination_type-01 .page-link, .fastlinks_item .center_content, .archive_carousel .slick-list .slick-track, .socials-navigation, .socials-navigation_list, .header, .footer-navigation_list,
.flex_column,
html,
body,
.hero_mask .hero_heading,
.charities_item-btn,
.grants_list-item_link,
.archive_item,
.archive_view-all,
.archive_carousel .slick-list .slick-slide,
.archive_carousel .slick-list .slick-slide > div,
.main-navigation,
.wrapper,
.footer-navigation {
  display: flex;
  flex-wrap: nowrap; }

.flex_row, .controls, .pagination_type-01, .pagination_type-01 .page-link, .fastlinks_item .center_content, .archive_carousel .slick-list .slick-track, .socials-navigation, .socials-navigation_list, .header, .footer-navigation_list {
  flex-direction: row; }


.flex_column,
html,
body,
.hero_mask .hero_heading,
.charities_item-btn,
.grants_list-item_link,
.archive_item,
.archive_view-all,
.archive_carousel .slick-list .slick-slide,
.archive_carousel .slick-list .slick-slide > div,
.main-navigation,
.wrapper,
.footer-navigation {
  flex-direction: column; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CLEARFIX --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.clearfix {
  *zoom: 1; }
  .clearfix:before, .clearfix:after {
    content: "";
    display: table;
    line-height: 0; }
  .clearfix:after {
    clear: both; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ALIGNMENT -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.left {
  float: left; }

.right {
  float: right; }

.center {
  margin: 0 auto; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wBase {
  max-width: 1240px; }

.wMedium {
  max-width: 980px; }

.wSmall {
  max-width: 720px; }

.wXSmall {
  max-width: 440px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BLEEDS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.bleed_top_24 {
  padding-top: 24px; }

.bleed_top_48 {
  padding-top: 48px; }

.bleed_top_96 {
  padding-top: 96px; }

.bleed_48 {
  padding: 48px; }

.bleed_96 {
  padding: 96px; }

@media screen and (max-width: 1300px) and (min-width: 769px) {
  .bleed_top_48,
  .bleed_top_96 {
    padding-top: 48px; }
  .bleed_48,
  .bleed_96 {
    padding: 48px; } }

@media screen and (max-width: 768px) {
  .bleed_top_48,
  .bleed_top_96 {
    padding-top: 24px; }
  .bleed_48,
  .bleed_96 {
    padding: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MARGINS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_margin {
  margin: 0 !important; }

.reset_margin_top {
  margin-top: 0 !important; }

.reset_margin_right {
  margin-right: 0 !important; }

.reset_margin_bottom {
  margin-bottom: 0 !important; }

.reset_margin_left {
  margin-left: 0 !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PADDINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_padding {
  padding: 0 !important; }

.reset_padding_top {
  padding-top: 0 !important; }

.reset_padding_right {
  padding-right: 0 !important; }

.reset_padding_bottom {
  padding-bottom: 0 !important; }

.reset_padding_left {
  padding-left: 0 !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_border {
  border: 0 solid !important; }

.reset_border_top {
  border-top: 0 solid !important; }

.reset_border_right {
  border-right: 0 solid !important; }

.reset_border_bottom {
  border-bottom: 0 solid !important; }

.reset_border_left {
  border-left: 0 solid !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* POSITIONS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.relative, .form .input_text, .form .field_select, .form .input_radio,
.form .input_checkbox, .lazyload_wrapper, .lazyload_wrapper picture,
.lazyload_wrapper img, .btn_slt.btn_has-icon, .form_type_1 .field, .form_type_1 .field-feedback, .hero, .wrapper_video, .gmap_type-01, .grant_info-box, .twitter-feed_item, .case-studies_carousel-item, .main-navigation_trigger button, .main-navigation_trigger button span, .typography a:not(.btn):not(.link), .site_title {
  position: relative;
  z-index: 1; }

.absolute, .form .field_select .chevron, .cookie_notification .cookie_close.icon, .button_close, .btn_slt.btn_has-icon:before, .btn_slt.btn_has-icon:after, .form_type_1 .field-feedback:before, .hero_background-image, .grant_info-box:before, .case-studies_carousel-item:before, .case-studies_carousel-item:after, .main-navigation_submenu:before, .main-navigation_trigger button span:before, .main-navigation_trigger button span:after, .header:after {
  position: absolute;
  z-index: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* COLOURS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Black(ish) --- */
.color_01-01,
a.color_01-01 {
  color: #1F2023 !important; }

@media screen and (min-width: 769px) {
  a.color_01-01:hover, a.color_01-01:focus {
    color: #1F2023 !important;
    cursor: pointer; } }

/* --- White --- */
.color_white,
a.color_white {
  color: #FFF !important; }

@media screen and (min-width: 769px) {
  a.color_02-03:hover, a.color_02-03:focus {
    color: #FFF !important;
    cursor: pointer; } }

/* --- Purple --- */
.color_02-01,
a.color_02-01 {
  color: #50396A !important; }

@media screen and (min-width: 769px) {
  a.color_02-01:hover, a.color_02-01:focus {
    color: #39294c !important;
    cursor: pointer; } }

/* --- Red --- */
.color_02-02,
a.color_02-02 {
  color: #8F3A41 !important; }

@media screen and (min-width: 769px) {
  a.color_02-02:hover, a.color_02-02:focus {
    color: #6e2d32 !important;
    cursor: pointer; } }

/* --- Green --- */
.color_02-03,
a.color_02-03 {
  color: #5B795E !important; }

@media screen and (min-width: 769px) {
  a.color_02-03:hover, a.color_02-03:focus {
    color: #475f4a !important;
    cursor: pointer; } }

/* --- Orange --- */
.color_02-04,
a.color_02-04 {
  color: #CE6A57 !important; }

@media screen and (min-width: 769px) {
  a.color_02-04:hover, a.color_02-04:focus {
    color: #bf4d38 !important;
    cursor: pointer; } }

/* --- Blue --- */
.color_02-05,
a.color_02-05 {
  color: #464B77 !important; }

@media screen and (min-width: 769px) {
  a.color_02-05:hover, a.color_02-05:focus {
    color: #35395a !important;
    cursor: pointer; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BACKGROUNDS ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- White --- */
.bg_white {
  background: #FFF !important; }

/* --- Black(ish) --- */
.bg_01-01,
a.bg_01-01,
button.bg_01-01 {
  background: #1F2023 !important; }

@media screen and (min-width: 769px) {
  a.bg_01-01:hover, a.bg_01-01:focus,
  button.bg_01-01:hover,
  button.bg_01-01:focus {
    background: #090a0b !important;
    cursor: pointer; } }

/* --- Purple --- */
.bg_02-01,
a.bg_02-01,
button.bg_02-01 {
  background: #50396A !important; }

@media screen and (min-width: 769px) {
  a.bg_02-01:hover, a.bg_02-01:focus,
  button.bg_02-01:hover,
  button.bg_02-01:focus {
    background: #39294c !important;
    cursor: pointer; } }

/* --- Red --- */
.bg_02-02,
a.bg_02-02,
button.bg_02-02 {
  background: #8F3A41 !important; }

@media screen and (min-width: 769px) {
  a.bg_02-02:hover, a.bg_02-02:focus,
  button.bg_02-02:hover,
  button.bg_02-02:focus {
    background: #6e2d32 !important;
    cursor: pointer; } }

/* --- Green --- */
.bg_02-03,
a.bg_02-03,
button.bg_02-03 {
  background: #5B795E !important; }

@media screen and (min-width: 769px) {
  a.bg_02-03:hover, a.bg_02-03:focus,
  button.bg_02-03:hover,
  button.bg_02-03:focus {
    background: #475f4a !important;
    cursor: pointer; } }

/* --- Orange --- */
.bg_02-04,
a.bg_02-04,
button.bg_02-04 {
  background: #CE6A57 !important; }

@media screen and (min-width: 769px) {
  a.bg_02-04:hover, a.bg_02-04:focus,
  button.bg_02-04:hover,
  button.bg_02-04:focus {
    background: #bf4d38 !important;
    cursor: pointer; } }

/* --- Blue --- */
.bg_02-05,
a.bg_02-05,
button.bg_02-05 {
  background: #464B77 !important; }

@media screen and (min-width: 769px) {
  a.bg_02-05:hover, a.bg_02-05:focus,
  button.bg_02-05:hover,
  button.bg_02-05:focus {
    background: #35395a !important;
    cursor: pointer; } }

/* --- Twitter --- */
.bg_twitter,
a.bg_twitter,
button.bg_twitter {
  background: #4099FF !important; }

@media screen and (min-width: 769px) {
  a.bg_twitter:hover, a.bg_twitter:focus,
  button.bg_twitter:hover,
  button.bg_twitter:focus {
    background: #1280ff !important;
    cursor: pointer; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HOVERS (COLOR) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Purple --- */
@media screen and (min-width: 769px) {
  .hover_color_02-01:hover, .hover_color_02-01:focus {
    color: #50396A !important; } }

/* --- Red --- */
@media screen and (min-width: 769px) {
  .hover_color_02-02:hover, .hover_color_02-02:focus {
    color: #8F3A41 !important; } }

/* --- Green --- */
@media screen and (min-width: 769px) {
  .hover_color_02-03:hover, .hover_color_02-03:focus {
    color: #5B795E !important; } }

/* --- Orange --- */
@media screen and (min-width: 769px) {
  .hover_color_02-04:hover, .hover_color_02-04:focus {
    color: #CE6A57 !important; } }

/* --- Blue --- */
@media screen and (min-width: 769px) {
  .hover_color_02-05:hover, .hover_color_02-05:focus {
    color: #464B77 !important; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FILLS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Purple --- */
.fill_02-01 svg {
  fill: #50396A !important; }

@media screen and (min-width: 769px) {
  a.fill_02-01:hover svg,
  a.fill_02-01:focus svg,
  button.fill_02-01:hover svg,
  button.fill_02-01:focus svg {
    fill: #39294c !important; } }

/* --- Red --- */
.fill_02-02 svg {
  fill: #8F3A41 !important; }

@media screen and (min-width: 769px) {
  a.fill_02-02:hover svg,
  a.fill_02-02:focus svg,
  button.fill_02-02:hover svg,
  button.fill_02-02:focus svg {
    fill: #6e2d32 !important; } }

/* --- Green --- */
.fill_02-03 svg {
  fill: #5B795E !important; }

@media screen and (min-width: 769px) {
  a.fill_02-03:hover svg,
  a.fill_02-03:focus svg,
  button.fill_02-03:hover svg,
  button.fill_02-03:focus svg {
    fill: #475f4a !important; } }

/* --- Orange --- */
.fill_02-04 svg {
  fill: #CE6A57 !important; }

@media screen and (min-width: 769px) {
  a.fill_02-04:hover svg,
  a.fill_02-04:focus svg,
  button.fill_02-04:hover svg,
  button.fill_02-04:focus svg {
    fill: #bf4d38 !important; } }

/* --- Blue --- */
.fill_02-05 svg {
  fill: #464B77 !important; }

@media screen and (min-width: 769px) {
  a.fill_02-05:hover svg,
  a.fill_02-05:focus svg,
  button.fill_02-05:hover svg,
  button.fill_02-05:focus svg {
    fill: #35395a !important; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HOVERS (FILL) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Purple --- */
@media screen and (min-width: 769px) {
  .hover_fill_02-01:hover svg,
  .hover_fill_02-01:focus svg {
    fill: #50396A !important; } }

/* --- Red --- */
@media screen and (min-width: 769px) {
  .hover_fill_02-02:hover svg,
  .hover_fill_02-02:focus svg {
    fill: #8F3A41 !important; } }

/* --- Green --- */
@media screen and (min-width: 769px) {
  .hover_fill_02-03:hover svg,
  .hover_fill_02-03:focus svg {
    fill: #5B795E !important; } }

/* --- Orange --- */
@media screen and (min-width: 769px) {
  .hover_fill_02-04:hover svg,
  .hover_fill_02-04:focus svg {
    fill: #CE6A57 !important; } }

/* --- Blue --- */
@media screen and (min-width: 769px) {
  .hover_fill_02-05:hover svg,
  .hover_fill_02-05:focus svg {
    fill: #464B77 !important; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Black(ish) --- */
.border_01-01,
a.border_01-01,
button.border_01-01 {
  color: #1F2023 !important;
  border: 2px solid #1F2023 !important; }

@media screen and (min-width: 769px) {
  a.border_01-01:hover, a.border_01-01:focus,
  button.border_01-01:hover,
  button.border_01-01:focus {
    color: #090a0b !important;
    border: 2px solid #090a0b !important;
    cursor: pointer; } }

/* --- Purple --- */
.border_02-01,
a.border_02-01,
button.border_02-01 {
  color: #50396A !important;
  border: 2px solid #50396A !important; }

@media screen and (min-width: 769px) {
  a.border_02-01:hover, a.border_02-01:focus,
  button.border_02-01:hover,
  button.border_02-01:focus {
    color: #39294c !important;
    border: 2px solid #39294c !important;
    cursor: pointer; } }

/* --- Red --- */
.border_02-02,
a.border_02-02,
button.border_02-02 {
  color: #8F3A41 !important;
  border: 2px solid #8F3A41 !important; }

@media screen and (min-width: 769px) {
  a.border_02-02:hover, a.border_02-02:focus,
  button.border_02-02:hover,
  button.border_02-02:focus {
    color: #6e2d32 !important;
    border: 2px solid #6e2d32 !important;
    cursor: pointer; } }

/* --- Green --- */
.border_02-03,
a.border_02-03,
button.border_02-03 {
  color: #5B795E !important;
  border: 2px solid #5B795E !important; }

@media screen and (min-width: 769px) {
  a.border_02-03:hover, a.border_02-03:focus,
  button.border_02-03:hover,
  button.border_02-03:focus {
    color: #475f4a !important;
    border: 2px solid #475f4a !important;
    cursor: pointer; } }

/* --- Orange --- */
.border_02-04,
a.border_02-04,
button.border_02-04 {
  color: #CE6A57 !important;
  border: 2px solid #CE6A57 !important; }

@media screen and (min-width: 769px) {
  a.border_02-04:hover, a.border_02-04:focus,
  button.border_02-04:hover,
  button.border_02-04:focus {
    color: #bf4d38 !important;
    border: 2px solid #bf4d38 !important;
    cursor: pointer; } }

/* --- Blue --- */
.border_02-05,
a.border_02-05,
button.border_02-05 {
  color: #464B77 !important;
  border: 2px solid #464B77 !important; }

@media screen and (min-width: 769px) {
  a.border_02-05:hover, a.border_02-05:focus,
  button.border_02-05:hover,
  button.border_02-05:focus {
    color: #35395a !important;
    border: 2px solid #35395a !important;
    cursor: pointer; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL SIZE -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full_size, .form label.in_field_label, .hero_mask .hero_background-image img, .gmap_type-01 .gmap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL WIDTH ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full_width, .lazyload_wrapper picture,
.lazyload_wrapper img {
  display: block;
  width: 100%;
  height: auto; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANIMATE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.animate, a.color_01-01, a.color_02-03, a.color_02-01, a.color_02-02, a.color_02-04, a.color_02-05, a.bg_01-01,
button.bg_01-01, a.bg_02-01,
button.bg_02-01, a.bg_02-02,
button.bg_02-02, a.bg_02-03,
button.bg_02-03, a.bg_02-04,
button.bg_02-04, a.bg_02-05,
button.bg_02-05, a.bg_twitter,
button.bg_twitter, .hover_color_02-01, .hover_color_02-02, .hover_color_02-03, .hover_color_02-04, .hover_color_02-05, .fill_02-01 svg, .fill_02-02 svg, .fill_02-03 svg, .fill_02-04 svg, .fill_02-05 svg, a.border_01-01,
button.border_01-01, a.border_02-01,
button.border_02-01, a.border_02-02,
button.border_02-02, a.border_02-03,
button.border_02-03, a.border_02-04,
button.border_02-04, a.border_02-05,
button.border_02-05, .form label.in_field_label, .form .input_text, .form .field_select .chevron, .form .input_radio span,
.form .input_checkbox span, .form .input_radio span:after,
.form .input_checkbox span svg, .field-feedback, .badge_valid,
.badge_invalid, .cookie_notification p > a, .cookie_notification .cookie_close, .btn, .form a, .form_type_1 input[type=tel],
.form_type_1 input[type=text],
.form_type_1 input[type=email],
.form_type_1 input[type=password],
.form_type_1 textarea, .controls_prev svg,
.controls_next svg, .pagination_type-01 .page-link, .pagination_type-01 .page-link svg, .twitter-feed_item-link a, .archive_item-thumb img, .archive_item-heading a, .archive_item-link, .article_meta-item_categories > li > a, .main-navigation_list, .main-navigation_list-item_link, .main-navigation_submenu, .main-navigation_submenu-item_link, .main-navigation_trigger button, .main-navigation_trigger button span, .main-navigation_trigger button span:before, .main-navigation_trigger button span:after, .socials-navigation_list-item_link, .socials-navigation_list-item_link svg, .typography a:not(.btn):not(.link), .header:after, .footer .footer_link, .footer-navigation_list-item_link {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* RESPONSIVENESS --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.only_desktop {
  display: none; }
  @media screen and (min-width: 769px) {
    .only_desktop {
      display: block; } }

.only_mobile {
  display: none; }
  @media screen and (max-width: 768px) {
    .only_mobile {
      display: block; } }

.visuallyHidden {
  display: none !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GRID SYSTEM ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* Widths */
  /* Column */
  /* Gutter 12px */
  /* Gutter 24px */
  /* Gutter 48px */
  /* Gutter 96px */ }
  .grid.grid_reverse {
    flex-direction: row-reverse; }
  @media screen and (max-width: 768px) {
    .grid {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  .grid .width_01-12 {
    width: calc(1 / 12 * 100%); }
  .grid .width_02-12 {
    width: calc(2 / 12 * 100%); }
  .grid .width_03-12 {
    width: calc(3 / 12 * 100%); }
  .grid .width_04-12 {
    width: calc(4 / 12 * 100%); }
  .grid .width_05-12 {
    width: calc(5 / 12 * 100%); }
  .grid .width_06-12 {
    width: calc(6 / 12 * 100%); }
  .grid .width_07-12 {
    width: calc(7 / 12 * 100%); }
  .grid .width_08-12 {
    width: calc(8 / 12 * 100%); }
  .grid .width_09-12 {
    width: calc(9 / 12 * 100%); }
  .grid .width_10-12 {
    width: calc(10 / 12 * 100%); }
  .grid .width_11-12 {
    width: calc(11 / 12 * 100%); }
  .grid .width_12-12 {
    width: calc(12 / 12 * 100%); }
  .grid .width_01-05 {
    width: calc(1 / 5 * 100%); }
  .grid .width_02-05 {
    width: calc(2 / 5 * 100%); }
  .grid .column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    box-sizing: border-box; }
    @media screen and (max-width: 768px) {
      .grid .column {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100%; } }
  .grid.gutter_12 {
    margin-left: -6px;
    margin-right: -6px; }
    .grid.gutter_12 > .column {
      padding-left: 6px;
      padding-right: 6px; }
  .grid.gutter_24 {
    margin-left: -12px;
    margin-right: -12px; }
    .grid.gutter_24 > .column {
      padding-left: 12px;
      padding-right: 12px; }
  .grid.gutter_48 {
    margin-left: -24px;
    margin-right: -24px; }
    .grid.gutter_48 > .column {
      padding-left: 24px;
      padding-right: 24px; }
  .grid.gutter_96 {
    margin-left: -48px;
    margin-right: -48px; }
    .grid.gutter_96 > .column {
      padding-left: 48px;
      padding-right: 48px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (RESET) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  line-height: 1.6;
  font-family: "proxima-nova", sans-serif;
  font-size: 100%;
  border: 0 solid;
  border-radius: 0;
  appearance: none; }

[type=tel],
[type=text],
[type=email],
[type=password] {
  display: block;
  width: 100%;
  background: transparent; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  appearance: none; }

[type=submit] {
  width: 100%;
  cursor: pointer;
  overflow: visible; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

fieldset {
  margin: 0;
  padding: 0;
  border: none; }

progress {
  display: inline-block;
  vertical-align: baseline; }

textarea {
  display: block;
  width: 100%;
  background: transparent;
  resize: none;
  overflow: auto;
  box-sizing: border-box; }

[type="checkbox"],
[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  appearance: textfield; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

[hidden] {
  display: none; }

select::-ms-expand {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* STYLE ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form {
  /* --- Label --- */
  /* --- Input text --- */
  /* --- Field select --- */
  /* --- Radio & Checkbox --- */ }
  .form label.field_label {
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
  .form label.in_field_label {
    box-sizing: border-box;
    cursor: pointer; }
  .form label em {
    flex: 1;
    padding-left: 12px;
    font-size: 14px;
    text-align: right;
    opacity: .48; }
  .form .field_select select {
    position: relative;
    z-index: 2;
    width: 100%;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer; }
    @media screen and (min-width: 769px) {
      .form .field_select select:focus + .chevron {
        transform: rotate(270deg); } }
  .form .field_select .chevron {
    top: 50%;
    transform: rotate(90deg); }
    .form .field_select .chevron svg {
      display: block; }
  .form .input_radio,
  .form .input_checkbox {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; }
    .form .input_radio input[type=radio],
    .form .input_radio input[type=checkbox],
    .form .input_checkbox input[type=radio],
    .form .input_checkbox input[type=checkbox] {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      cursor: pointer; }
    .form .input_radio span,
    .form .input_checkbox span {
      display: block;
      text-align: center;
      box-sizing: border-box; }
    .form .input_radio label,
    .form .input_checkbox label {
      flex: 1;
      flex-direction: column;
      margin: 0;
      padding: 0;
      line-height: 1.4;
      cursor: pointer; }
    .form .input_radio input[type=radio]:checked + span:after,
    .form .input_radio input[type=checkbox]:checked + span svg,
    .form .input_checkbox input[type=radio]:checked + span:after,
    .form .input_checkbox input[type=checkbox]:checked + span svg {
      opacity: 1; }
  .form .input_radio span,
  .form .input_radio span:after {
    border-radius: 100%; }
  .form .input_radio span:after {
    content: ""; }
  .form .input_radio span:after,
  .form .input_checkbox span svg {
    display: block;
    opacity: 0; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* VALIDATION ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form_alert {
  margin-top: -1px;
  padding-bottom: 2px;
  text-align: center; }
  .form_alert.is-valid {
    color: #00B16A; }
  .form_alert.is-invalid {
    color: #D91E18; }

/* --- Feedback --- */
.field-feedback {
  max-height: 0;
  overflow: hidden;
  opacity: 0; }

/* --- Badges --- */
.badge_valid,
.badge_invalid {
  position: absolute;
  top: 0;
  right: 24px;
  bottom: 0;
  z-index: 2;
  width: 16px;
  opacity: 0; }
  .badge_valid svg,
  .badge_invalid svg {
    display: block;
    margin-top: 24px;
    width: 16px;
    height: 16px; }

.badge_valid svg {
  fill: #00B16A; }

.badge_invalid svg {
  fill: #D91E18; }

.input_radio .badge_valid,
.input_radio .badge_invalid,
.input_checkbox .badge_valid,
.input_checkbox .badge_invalid {
  top: 0;
  left: 25px;
  right: auto;
  width: 12px;
  height: 12px;
  transform: translateY(-50%); }
  .input_radio .badge_valid svg,
  .input_radio .badge_invalid svg,
  .input_checkbox .badge_valid svg,
  .input_checkbox .badge_invalid svg {
    display: none; }

/* --- Valid --- */
.is-valid .field-feedback,
.is-valid .icon.badge_invalid {
  opacity: 0; }

.is-valid .icon.badge_valid {
  opacity: 1; }

/* --- Invalid --- */
.is-invalid .icon.badge_valid {
  opacity: 0; }

.is-invalid .field-feedback,
.is-invalid .icon.badge_invalid {
  opacity: 1; }

.is-invalid .field-feedback {
  max-height: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.no-js {
  /* --- Select --- */ }
  .no-js .field_select span {
    display: none; }
  .no-js .field_select select {
    position: static;
    padding: 16px 20px 17px;
    border: 2px solid rgba(31, 32, 35, 0.12);
    opacity: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  font-weight: 700;
  font-family: "museo-sans", sans-serif; }

h1, .h1 {
  line-height: 1.2;
  font-size: 48px; }
  @media screen and (max-width: 768px) {
    h1, .h1 {
      font-size: 32px; } }

h2, .h2 {
  line-height: 1.3;
  font-size: 32px; }
  @media screen and (max-width: 768px) {
    h2, .h2 {
      font-size: 24px; } }

h3, .h3 {
  line-height: 1.4;
  font-size: 24px; }
  @media screen and (max-width: 768px) {
    h3, .h3 {
      font-size: 18px; } }

h4, .h4 {
  font-size: 18px;
  font-weight: 400; }
  @media screen and (max-width: 768px) {
    h4, .h4 {
      font-size: 18px; } }

h5, .h5,
h6, .h6 {
  line-height: 1.6;
  font-size: 18px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HR LINES --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
hr {
  height: 0;
  overflow: visible;
  box-sizing: content-box; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANCHORS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
a {
  color: #1F2023;
  text-decoration: none;
  text-decoration-skip: objects;
  background-color: transparent; }
  a:active, a:hover {
    outline-width: 0; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT BOLD -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
b,
strong {
  font-weight: inherit;
  font-weight: bold; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT ITALIC ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
i,
em,
dfn {
  font-style: italic; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT HIGHLIGHTED ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
mark {
  color: #333;
  background: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT SMALL ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
small,
.small {
  font-size: 14px; }

sub,
sup {
  position: relative;
  line-height: 0;
  font-size: 75%;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* IMAGES ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
figure {
  display: block;
  margin: 0; }
  figure figcaption {
    display: block; }

img {
  width: auto\9;
  height: auto;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

/* FUNCTIONS */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* MODAL WINDOW ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-modal_wrapper {
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.88);
  opacity: 0;
  visibility: hidden; }
  @media screen and (max-width: 768px) {
    .js-modal_wrapper:not(.modal_flash_message) {
      background: #FFF;
      overflow: auto; }
      .js-modal_wrapper:not(.modal_flash_message) .js-modal_content {
        position: static;
        top: 0;
        left: 0;
        padding: 0;
        width: auto;
        max-width: none;
        min-height: 0;
        background: none;
        overflow: auto;
        transform: none; }
    .js-modal_wrapper.modal_flash_message .js-modal_content {
      padding: 24px; }
      .js-modal_wrapper.modal_flash_message .js-modal_content.wXSmall {
        max-width: calc(440px + 48px); }
      .js-modal_wrapper.modal_flash_message .js-modal_content .box_type_1 {
        padding: 24px 60px 24px 24px; }
      .js-modal_wrapper.modal_flash_message .js-modal_content .button_close {
        top: -12px;
        right: -12px;
        border-radius: 6px; } }
  .js-modal_wrapper .js-modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    padding: 0 48px;
    width: 100%;
    box-sizing: border-box;
    transform: translate(-50%, -50%); }
    .js-modal_wrapper .js-modal_content.wXSmall {
      max-width: calc(440px + 96px); }
  .js-modal_wrapper .js-modal_trigger {
    display: inline-block; }
  .js-modal_wrapper.fixedHeight {
    overflow: auto; }
    .js-modal_wrapper.fixedHeight .js-modal_content {
      position: relative;
      top: auto;
      left: auto;
      margin: 24px auto;
      transform: none; }
      @media screen and (max-width: 768px) {
        .js-modal_wrapper.fixedHeight .js-modal_content {
          margin: 0; } }
  .js-modal_wrapper.open {
    opacity: 1;
    visibility: visible; }
  .js-modal_wrapper.modal_error .js-modal_content .box,
  .js-modal_wrapper.modal_invalid .js-modal_content .box {
    border-left: 4px solid #D91E18; }
  .js-modal_wrapper.modal_alert .js-modal_content .box {
    border-left: 4px solid #F89406; }
  .js-modal_wrapper.modal_valid .js-modal_content .box {
    border-left: 4px solid #00B16A; }
  .js-modal_wrapper.modal_update .js-modal_content .box {
    border-left: 4px solid #1E8BC3; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-modal_trigger_no-js {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important; }

.no-js .js-modal_wrapper {
  z-index: 1; }
  .no-js .js-modal_wrapper:target {
    z-index: 99999;
    opacity: 1;
    visibility: visible; }

.no-js .js-modal_trigger_no-js {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important; }
  .no-js .js-modal_trigger_no-js + a {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important; }

/* PLUGINS */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAZYLOAD --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.lazyload_wrapper picture,
.lazyload_wrapper img {
  opacity: 0; }
  .lazyload_wrapper picture.lazyloaded,
  .lazyload_wrapper img.lazyloaded {
    opacity: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.no-js .lazyload_wrapper picture,
.no-js .lazyload_wrapper img {
  opacity: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* COOKIE NOTIFICATION ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.cookie_notification {
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 99;
  padding: 32px;
  width: 100%;
  max-width: 200px;
  line-height: 1.4;
  font-size: 14px;
  text-align: center;
  background: #FFF;
  border-radius: 6px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.12); }
  @media screen and (max-width: 768px) {
    .cookie_notification {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      left: 12px;
      right: 12px;
      bottom: 12px;
      padding: 24px;
      width: auto;
      max-width: none;
      text-align: left;
      box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.12); } }
  .cookie_notification svg {
    display: block;
    margin: 0 auto;
    padding-bottom: 24px;
    width: 48px;
    height: 48px;
    fill: #50396A; }
    @media screen and (max-width: 768px) {
      .cookie_notification svg {
        margin: 0;
        padding: 0 18px 0 0; } }
  .cookie_notification p > a {
    color: #1F2023;
    text-decoration: underline; }
    @media screen and (min-width: 769px) {
      .cookie_notification p > a:hover, .cookie_notification p > a:focus {
        text-decoration: none; } }
    @media screen and (max-width: 768px) {
      .cookie_notification p > a {
        flex: 1;
        color: #50396A; } }
  .cookie_notification .cookie_close.anchor {
    display: inline-block;
    margin: 12px auto 0;
    color: #50396A;
    text-decoration: none; }
    @media screen and (min-width: 769px) {
      .cookie_notification .cookie_close.anchor:hover, .cookie_notification .cookie_close.anchor:focus {
        color: #39294c; } }
    @media screen and (max-width: 768px) {
      .cookie_notification .cookie_close.anchor {
        flex: 1 0 auto;
        margin: 18px -24px -24px;
        padding: 18px 0 24px;
        width: 100%;
        text-align: center;
        border-top: 1px solid #EEECE7; } }
  .cookie_notification .cookie_close.icon {
    top: 16px;
    right: 16px;
    padding: 4px;
    width: 16px;
    height: 16px;
    opacity: .48; }
    .cookie_notification .cookie_close.icon svg {
      display: block;
      width: 16px;
      height: 16px;
      fill: #50396A; }
      @media screen and (max-width: 768px) {
        .cookie_notification .cookie_close.icon svg {
          width: 12px;
          height: 12px; } }
    @media screen and (min-width: 769px) {
      .cookie_notification .cookie_close.icon:hover, .cookie_notification .cookie_close.icon:focus {
        opacity: 1; } }
    @media screen and (max-width: 768px) {
      .cookie_notification .cookie_close.icon {
        top: 0;
        right: 0;
        padding: 12px;
        width: 12px;
        height: 12px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* COMPONENTS */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn {
  display: inline-block;
  background: none;
  cursor: pointer; }
  .btn.btn_has-icon {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center; }
    .btn.btn_has-icon svg {
      display: block;
      flex-shrink: 0;
      padding-left: 12px;
      width: 18px;
      height: 18px; }
      .btn.btn_has-icon svg:first-child {
        padding-left: 0;
        padding-right: 12px; }
  .btn.btn_is-uppercase {
    font-size: 16px;
    text-transform: uppercase; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (ROUND) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn_round,
.btn_round-ghost {
  font-weight: 600;
  border-radius: 999px; }

.btn_round {
  padding: 16px 28px; }

.btn_round-ghost {
  padding: 14px 26px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (CLOSE) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.button_close {
  top: -12px;
  right: -12px;
  width: 48px;
  height: 48px;
  border-radius: 6px;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    .button_close {
      top: 0;
      right: 0;
      border-radius: 0 0 0 6px; } }
  .button_close svg {
    display: block;
    padding: 15px;
    width: 18px;
    height: 18px;
    fill: #FFF; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (SLT) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn_slt.btn_has-icon {
  padding: 12px 24px;
  line-height: 1.2;
  font-weight: 600;
  text-align: left;
  border-left: 4px solid #D2D1CD; }
  .btn_slt.btn_has-icon:before, .btn_slt.btn_has-icon:after {
    content: "";
    top: 2px;
    left: -10px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    border-top: 4px solid #D2D1CD;
    border-left: 4px solid #D2D1CD; }
  .btn_slt.btn_has-icon svg {
    width: 32px;
    height: 32px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPE 1) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading_type-01, .hero_homepage-boxes_item-heading {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX -------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX (TYPE 1 & 2) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box_type-01,
.box_type-02 {
  padding: 32px; }
  @media screen and (max-width: 768px) {
    .box_type-01,
    .box_type-02 {
      padding: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX (TYPE 1) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box_type-01 {
  background: #FFF;
  border-radius: 6px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.12); }

.form a {
  text-decoration: underline; }
  @media screen and (min-width: 769px) {
    .form a:hover, .form a:focus {
      text-decoration: none; } }

.small_info {
  padding: 24px 24px 0;
  line-height: 1.4;
  font-size: 14px; }
  @media screen and (max-width: 768px) {
    .small_info {
      margin: 0 -24px;
      padding: 24px;
      border-bottom: 1px solid #DAD6CD; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (TYPE 1) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form_type_1 {
  margin-top: 6px;
  /* --- Field --- */
  /* --- Input text --- */
  /* --- Select --- */
  /* --- Input checkbox & radio --- */
  /* --- Input Submit --- */
  /* --- Feedback --- */ }
  @media screen and (max-width: 768px) {
    .form_type_1 {
      margin-top: 0; } }
  .form_type_1 a {
    color: #1F2023; }
  .form_type_1 .field {
    padding-top: 6px; }
    .form_type_1 .field label {
      color: #1F2023;
      font-size: 18px;
      font-weight: 600; }
      @media screen and (max-width: 768px) {
        .form_type_1 .field label {
          font-size: 16px; } }
      .form_type_1 .field label.field_label {
        padding: 12px 0 6px; }
  .form_type_1 input[type=tel],
  .form_type_1 input[type=text],
  .form_type_1 input[type=email],
  .form_type_1 input[type=password],
  .form_type_1 textarea {
    padding: 16px 64px 16px 24px;
    color: #1F2023;
    font-size: 18px;
    background: #FFF;
    border: 2px solid #EEECE7;
    border-radius: 6px;
    box-sizing: border-box; }
  .form_type_1 textarea {
    min-height: 120px; }
  .form_type_1 .field_select {
    background: #FFF;
    border: 2px solid #EEECE7;
    border-radius: 6px;
    box-sizing: border-box; }
    .form_type_1 .field_select select {
      display: block;
      padding: 16px 66px 16px 24px;
      width: 100% !important;
      height: 100% !important;
      color: #1F2023;
      font-size: 18px;
      background: none; }
    .form_type_1 .field_select .chevron {
      right: 24px;
      margin-top: -9px;
      width: 18px;
      height: 18px; }
      .form_type_1 .field_select .chevron svg {
        width: 18px;
        height: 18px;
        fill: #1F2023; }
  .form_type_1 .enquiry_contact-checkbox_wrapper {
    padding-top: 12px; }
  @media screen and (max-width: 768px) {
    .form_type_1 .input_radio,
    .form_type_1 .input_checkbox {
      margin-top: 6px; } }
  .form_type_1 .input_radio input[type=radio],
  .form_type_1 .input_radio input[type=checkbox],
  .form_type_1 .input_checkbox input[type=radio],
  .form_type_1 .input_checkbox input[type=checkbox] {
    width: 32px;
    height: 32px; }
  .form_type_1 .input_radio span,
  .form_type_1 .input_checkbox span {
    margin-right: 12px;
    width: 32px;
    height: 32px;
    background: #FFF;
    border: 2px solid #EEECE7;
    border-radius: 6px; }
  .form_type_1 .input_radio label,
  .form_type_1 .input_checkbox label {
    color: #727272;
    font-weight: 400; }
  .form_type_1 .input_radio span:after,
  .form_type_1 .input_checkbox span svg {
    margin: 6px;
    width: 16px;
    height: 16px; }
  .form_type_1 .input_radio span:after {
    background: #FFF; }
  .form_type_1 .input_checkbox span svg {
    fill: #1F2023; }
  .form_type_1 .wrapper_submit {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px; }
    .form_type_1 .wrapper_submit .input_submit {
      width: auto;
      background: none; }
  .form_type_1 .field-feedback:before {
    content: "";
    top: 0;
    left: 24px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #D91E18; }
  .form_type_1 .field-feedback span {
    display: block;
    margin-top: 6px;
    padding: 12px 24px;
    line-height: 1.4;
    font-size: 14px;
    color: #FFF;
    background: #D91E18;
    border-radius: 6px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE RECAPTCHA ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.g-recaptcha {
  display: none !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero {
  padding: 0 96px;
  background: #50396A; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .hero {
      padding: 0 48px; } }
  @media screen and (max-width: 768px) {
    .hero {
      padding: 0 24px; } }
  .hero .center_content {
    position: relative;
    z-index: 2;
    padding: 96px 0; }
    @media screen and (max-width: 1024px) and (min-width: 769px) {
      .hero .center_content {
        padding: 48px 0; } }
    @media screen and (max-width: 768px) {
      .hero .center_content {
        padding: 24px 0; } }

.hero_heading {
  color: #FFF;
  width: 55%;
  max-width: 720px; }
  @media screen and (max-width: 768px) {
    .hero_heading br {
      display: none; } }

.hero_preheading {
  display: block;
  padding-bottom: 24px;
  line-height: 1.4;
  color: #EEECE7;
  font-size: 18px;
  font-weight: 400; }
  @media screen and (max-width: 768px) {
    .hero_preheading {
      padding-bottom: 6px; } }

.hero_background-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 50vw;
  max-width: 660px;
  height: 50vw;
  max-height: 660px;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .hero_background-wrapper {
      display: none; } }

.hero_background {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: calc(100vw - 192px);
  max-width: 1320px;
  height: calc(100vw - 192px);
  max-height: 1320px;
  border-radius: 100%;
  overflow: hidden;
  transform: translate(50%, -50%); }
  @media screen and (max-width: 768px) {
    .hero_background {
      left: 50%;
      right: auto;
      width: calc(100vw + 192px);
      height: 100vw;
      transform: translateX(-50%);
      border-radius: 0 0 100% 100%;
      mix-blend-mode: luminosity; } }

.hero_background-image {
  left: -50%;
  bottom: 0;
  width: 100%;
  transform: translateX(25%); }
  @media screen and (max-width: 768px) {
    .hero_background-image {
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      transform: none; }
      .hero_background-image:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        background: linear-gradient(to bottom, #50396a 0%, rgba(80, 57, 106, 0) 100%); } }
  .hero_background-image img {
    object-fit: cover;
    font-family: 'object-fit:cover;'; }
    @media screen and (max-width: 768px) {
      .hero_background-image img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        filter: grayscale(100%); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (HOMEPAGE) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero_homepage {
  overflow: hidden; }

.hero_homepage-boxes {
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .hero_homepage-boxes {
      padding-top: 24px; } }
  @media screen and (max-width: 768px) {
    .hero_homepage-boxes > .grid .column:not(:first-child) {
      padding-top: 6px; } }

.hero_homepage-boxes_item {
  line-height: 1.4;
  text-align: center; }

.hero_homepage-boxes_item-heading {
  color: #1F2023;
  font-size: 18px; }

.hero_homepage-boxes_item-subheading {
  padding-top: .75em; }

.hero_homepage-boxes_item-btn {
  margin-top: auto;
  padding-top: 1.25em; }

.hero_homepage-background {
  background: #452E5E; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (STANDARD) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero_mask {
  overflow: hidden; }
  .hero_mask .hero_heading {
    max-width: 440px; }
  .hero_mask .hero_preheading {
    order: 2;
    padding-top: 24px;
    padding-bottom: 0; }
    @media screen and (max-width: 768px) {
      .hero_mask .hero_preheading {
        padding-top: 6px; } }
  .hero_mask .hero_background-wrapper {
    top: 50%;
    max-width: 980px;
    max-height: 980px;
    transform: translateY(-50%); }
  .hero_mask .hero_background {
    max-width: 1960px;
    max-height: 1960px;
    transform: translate(50%, -25%); }
  .hero_mask .hero_background-image {
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 50%;
    transform: translate(-25%, 50%); }

/* --------------------------------------------------------------------------------------------------- */
/* VIDEO --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.wrapper_video {
  padding-bottom: 56.25%;
  height: 0;
  background: #EEECE7;
  overflow: hidden; }
  .wrapper_video video,
  .wrapper_video iframe {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE MAP (TYPE 1) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.gmap_type-01 {
  padding-top: 75%;
  background: #FFF;
  border-radius: 6px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.12); }

.controls button + button {
  margin-left: 24px; }

.controls_prev,
.controls_next {
  width: 24px;
  height: 24px;
  background: transparent;
  cursor: pointer; }
  .controls_prev svg,
  .controls_next svg {
    width: 24px;
    height: 24px;
    fill: #1F2023; }
  @media screen and (min-width: 769px) {
    .controls_prev:hover svg,
    .controls_prev:focus svg,
    .controls_next:hover svg,
    .controls_next:focus svg {
      fill: #50396A; } }

.controls_prev {
  transform: rotate(180deg); }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION (TYPE 1) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pagination_type-01 {
  justify-content: space-between;
  margin-top: 48px;
  padding-top: 24px;
  border-top: 1px solid #DAD6CD; }
  @media screen and (max-width: 768px) {
    .pagination_type-01 {
      margin-top: 24px; } }
  .pagination_type-01 .page-link {
    align-items: center;
    line-height: 1; }
    .pagination_type-01 .page-link span {
      margin-top: -2px; }
    .pagination_type-01 .page-link svg {
      display: block;
      padding-left: 12px;
      width: 18px;
      height: 18px;
      fill: #1F2023; }
    .pagination_type-01 .page-link.page-link_prev svg {
      transform: rotate(180deg); }
    .pagination_type-01 .page-link.disabled {
      opacity: .32; }
      .pagination_type-01 .page-link.disabled svg {
        color: #727272; }
    @media screen and (min-width: 769px) {
      .pagination_type-01 .page-link:not(.disabled):hover, .pagination_type-01 .page-link:not(.disabled):focus {
        color: #50396A; }
        .pagination_type-01 .page-link:not(.disabled):hover svg, .pagination_type-01 .page-link:not(.disabled):focus svg {
          fill: #50396A; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FASTLINKS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.fastlinks {
  border-bottom: 2px solid #DAD6CD; }

.fastlinks_item {
  padding: 32px 96px; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .fastlinks_item {
      padding: 24px 48px; } }
  @media screen and (max-width: 768px) {
    .fastlinks_item {
      padding: 24px; } }
  .fastlinks_item:not(:first-child) {
    border-top: 2px solid #DAD6CD; }

.fastlinks_item .center_content {
  align-items: center; }

.fastlinks_item-dot {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border-radius: 100%; }
  @media screen and (max-width: 768px) {
    .fastlinks_item-dot {
      display: none; } }

.fastlinks_item-content {
  flex: 1 1 auto;
  padding: 0 32px; }

.fastlinks_item-content_heading {
  color: #1F2023; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .fastlinks_item-content_heading {
      font-size: 24px; } }
  @media screen and (max-width: 768px) {
    .fastlinks_item-content_heading {
      padding: 0;
      padding-right: 24px;
      font-size: 18px; } }

.fastlinks_item-content_excerpt {
  padding-top: 12px;
  width: 100%;
  max-width: 720px; }

.fastlinks_item-btn {
  flex-shrink: 0; }
  @media screen and (max-width: 768px) {
    .fastlinks_item-btn a {
      padding: 0; } }
  @media screen and (max-width: 768px) {
    .fastlinks_item-btn a span {
      display: none; } }
  .fastlinks_item-btn a svg {
    display: none; }
    @media screen and (max-width: 768px) {
      .fastlinks_item-btn a svg {
        display: block;
        padding: 15px;
        width: 18px;
        height: 18px;
        fill: #FFF; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CHARITIES -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.charities {
  padding: 96px;
  text-align: center; }
  @media screen and (max-width: 1224px) and (min-width: 1025px) {
    .charities {
      padding: 96px 12px; } }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .charities {
      padding: 48px 12px; } }
  @media screen and (max-width: 768px) {
    .charities {
      padding: 24px; } }
  .charities .grid {
    margin-top: -12px; }
    @media screen and (max-width: 1056px) and (min-width: 769px) {
      .charities .grid .column {
        width: 50%; } }

.charities_heading {
  padding-bottom: 48px; }
  @media screen and (max-width: 1024px) {
    .charities_heading {
      padding-bottom: 24px; } }

.charities_item {
  margin-top: 12px;
  line-height: 1.4; }

.charities_item-logo {
  display: block;
  padding-bottom: 24px; }
  .charities_item-logo img {
    display: block;
    margin: 0 auto;
    width: auto;
    height: 64px; }

.charities_item-heading {
  line-height: 1.2;
  color: #1F2023; }

.charities_item-desc {
  padding-top: 18px; }
  @media screen and (max-width: 768px) {
    .charities_item-desc {
      padding-top: 12px; } }

.charities_item-btn {
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
  padding-top: 32px; }
  @media screen and (max-width: 768px) {
    .charities_item-btn {
      padding-top: 18px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GRANTS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.grants {
  padding: 0 96px; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .grants {
      padding: 48px; } }
  @media screen and (max-width: 768px) {
    .grants {
      padding: 24px; } }

.grants_intro {
  padding-top: 96px; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .grants_intro {
      padding-top: 48px; } }
  @media screen and (max-width: 768px) {
    .grants_intro {
      padding-top: 0; } }

.grants_list {
  margin-top: -12px !important;
  padding: 48px 0 96px; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .grants_list {
      padding: 48px 0; } }
  @media screen and (max-width: 768px) {
    .grants_list {
      padding: 24px 0 0; } }

.grants_list-item {
  margin-top: 48px; }
  @media screen and (max-width: 768px) {
    .grants_list-item {
      margin-top: 24px; } }

.grants_list-item_heading {
  color: #1F2023; }

.grants_list-item_subheading {
  padding-top: 6px; }

.grants_list-item_content {
  padding-top: 18px; }

.grants_list-item_link {
  margin-top: auto;
  padding-top: 24px; }
  .grants_list-item_link a {
    text-align: center; }

.grants_list-nav {
  margin-top: -12px; }
  .grants_list-nav ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none; }
    @media screen and (max-width: 768px) {
      .grants_list-nav ul {
        flex-direction: column; } }
    .grants_list-nav ul li {
      padding-top: 12px; }
      @media screen and (min-width: 769px) {
        .grants_list-nav ul li:not(:last-child) {
          padding-right: 12px; } }
      @media screen and (max-width: 768px) {
        .grants_list-nav ul li .btn {
          width: 100%; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GRANT INFO BOX --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.grant_info-box {
  flex: 0 1 auto;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .grant_info-box {
      margin-top: 24px; } }
  .grant_info-box:before {
    content: "";
    top: -48px;
    right: 64px;
    width: 440px;
    height: 360px;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.15);
    mix-blend-mode: overlay; }

.grant_info-box_content {
  position: relative;
  z-index: 2; }
  .grant_info-box_content .typography p {
    color: #FFF; }

.grant_info-box_links-wrapper {
  margin: 24px -32px 0;
  padding: 24px 32px 0;
  color: #FFF;
  border-top: 1px solid rgba(255, 255, 255, 0.12); }
  @media screen and (max-width: 768px) {
    .grant_info-box_links-wrapper {
      margin: 24px -24px 0;
      padding: 24px 24px 0; } }
  .grant_info-box_links-wrapper .btn {
    display: block;
    margin-top: 18px;
    text-align: center; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TWITTER FEED ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.twitter-feed {
  padding: 96px;
  background: #50396A; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .twitter-feed {
      padding: 48px; } }
  @media screen and (max-width: 768px) {
    .twitter-feed {
      padding: 24px; } }
  @media screen and (max-width: 910px) and (min-width: 769px) {
    .twitter-feed .grid .column.width_03-12 {
      width: 33%; }
    .twitter-feed .grid .column.width_09-12 {
      width: 67%; } }
  @media screen and (min-width: 769px) {
    .twitter-feed .slick-list {
      margin-left: -6px;
      margin-right: -6px; } }
  .twitter-feed .slick-list .slick-track {
    margin: 0; }
    @media screen and (min-width: 769px) {
      .twitter-feed .slick-list .slick-track .slick-slide {
        padding-left: 6px;
        padding-right: 6px; } }

.twitter-feed_heading {
  padding-right: 36px;
  color: #FFF; }
  @media screen and (max-width: 768px) {
    .twitter-feed_heading {
      padding: 0 0 24px; } }

.twitter-feed_preheading {
  display: block;
  color: rgba(255, 255, 255, 0.64);
  font-size: 18px;
  font-weight: 400; }
  @media screen and (min-width: 769px) {
    .twitter-feed_preheading {
      padding-bottom: 6px; } }

.twitter-feed_btn {
  padding-top: 24px; }
  @media screen and (max-width: 768px) {
    .twitter-feed_btn {
      display: none; } }

@media screen and (max-width: 768px) {
  .twitter-feed_carousel {
    margin: 0 -24px;
    width: calc(100% + 48px) !important; } }

@media screen and (max-width: 768px) {
  .twitter-feed_carousel .slick-slide {
    padding: 0 24px; } }

.twitter-feed_carousel-controls {
  display: none; }
  @media screen and (max-width: 768px) {
    .twitter-feed_carousel-controls {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding-top: 24px;
      width: 100%; } }

@media screen and (max-width: 768px) {
  .twitter-feed_carousel-controls_prev svg,
  .twitter-feed_carousel-controls_next svg {
    fill: #FFF; } }

.twitter-feed_item {
  text-align: center;
  box-sizing: border-box; }

.twitter-feed_item-icon {
  margin: 0 auto;
  width: 32px;
  height: 32px; }
  @media screen and (max-width: 768px) {
    .twitter-feed_item-icon {
      position: absolute;
      right: 9px;
      bottom: 9px;
      z-index: 1;
      width: 18px;
      height: 18px; } }
  .twitter-feed_item-icon svg {
    display: block;
    width: 32px;
    height: 32px;
    fill: #4099FF; }
    @media screen and (max-width: 768px) {
      .twitter-feed_item-icon svg {
        width: 18px;
        height: 18px;
        fill: #DAD6CD; } }

.twitter-feed_item-content {
  line-height: 1.4;
  font-style: italic; }
  @media screen and (min-width: 769px) {
    .twitter-feed_item-content {
      padding-top: 24px; } }

.twitter-feed_item-link {
  margin-bottom: -16px;
  padding-top: 32px;
  font-size: 14px; }
  @media screen and (max-width: 768px) {
    .twitter-feed_item-link {
      padding-top: 16px; } }
  .twitter-feed_item-link a {
    opacity: .48; }
    @media screen and (min-width: 769px) {
      .twitter-feed_item-link a:hover, .twitter-feed_item-link a:focus {
        color: #4099FF;
        opacity: 1; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARCHIVE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
section.archive {
  padding: 96px; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    section.archive {
      padding: 48px; } }
  @media screen and (max-width: 768px) {
    section.archive {
      padding: 24px; } }
  section.archive .grid {
    margin-top: -48px; }
    @media screen and (max-width: 768px) {
      section.archive .grid {
        margin-top: -24px; } }

.archive_heading {
  padding-bottom: 48px;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .archive_heading {
      padding-bottom: 12px; } }

.archive_item {
  flex: 1 1 auto;
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .archive_item {
      padding-top: 24px; } }

.archive_item-thumb {
  display: block;
  background: #50396A; }
  .archive_item-thumb img {
    aspect-ratio: attr(width)/attr(height); }
  @media screen and (min-width: 769px) {
    .archive_item-thumb:hover img,
    .archive_item-thumb:focus img {
      opacity: .72; } }

.archive_item-date {
  padding-top: 18px; }

.archive_item-heading {
  padding-top: 12px;
  color: #1F2023;
  font-size: 18px;
  text-transform: uppercase; }
  @media screen and (min-width: 769px) {
    .archive_item-heading a:hover, .archive_item-heading a:focus {
      color: #50396A; } }

.archive_item-excerpt {
  padding-top: 12px;
  line-height: 1.4; }

.archive_item-link {
  align-self: flex-start;
  margin-top: auto;
  padding-top: 18px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline; }
  @media screen and (min-width: 769px) {
    .archive_item-link:hover, .archive_item-link:focus {
      color: #50396A;
      text-decoration: none; } }

.archive_view-all {
  align-items: center;
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .archive_view-all {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-top: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARCHIVE (CAROUSEL) ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.archive_carousel .slick-list {
  margin-left: -24px;
  margin-right: -24px; }
  @media screen and (min-width: 769px) {
    .archive_carousel .slick-list {
      margin-top: -48px; } }
  @media screen and (max-width: 768px) {
    .archive_carousel .slick-list {
      margin-top: -12px; } }
  .archive_carousel .slick-list .slick-track {
    margin: 0; }
  .archive_carousel .slick-list .slick-slide {
    padding: 0 24px;
    height: auto !important; }
    .archive_carousel .slick-list .slick-slide > div {
      flex: 1 1 auto; }
      .archive_carousel .slick-list .slick-slide > div > .column {
        display: flex !important;
        flex-direction: column;
        flex-wrap: nowrap;
        flex: 1 1 auto; }

.archive_carousel-controls {
  display: none; }
  @media screen and (max-width: 768px) {
    .archive_carousel-controls {
      display: block;
      flex: 1 1 auto; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARTICLE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.article_heading {
  color: #FFF;
  width: 100%;
  max-width: 720px; }

.article_meta {
  padding: 0 96px;
  background: #50396A; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .article_meta {
      padding: 0 48px; } }
  @media screen and (max-width: 768px) {
    .article_meta {
      padding: 0 24px; } }
  .article_meta .center_content {
    padding: 96px 0; }
    @media screen and (max-width: 1024px) and (min-width: 769px) {
      .article_meta .center_content {
        padding: 48px 0; } }
    @media screen and (max-width: 768px) {
      .article_meta .center_content {
        padding: 24px 0; } }

.article_meta-item {
  padding-top: 24px;
  color: rgba(255, 255, 255, 0.64);
  font-size: 18px; }
  @media screen and (max-width: 768px) {
    .article_meta-item {
      padding-top: 12px; } }

.article_meta-item_date {
  color: #EEECE7; }

.article_meta-item_categories {
  display: inline;
  list-style: none; }
  .article_meta-item_categories > li {
    display: inline; }
    .article_meta-item_categories > li > a {
      color: #EEECE7;
      font-weight: 500;
      text-decoration: underline; }
      @media screen and (min-width: 769px) {
        .article_meta-item_categories > li > a:hover, .article_meta-item_categories > li > a:focus {
          color: #FFF;
          text-decoration: none; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CASE STUDIES ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* CASE STUDIES (CAROUSEL) ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.case-studies_carousel {
  padding: 96px;
  background: #DAD6CD; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .case-studies_carousel {
      padding: 48px; } }
  @media screen and (max-width: 768px) {
    .case-studies_carousel {
      position: relative;
      z-index: 1;
      padding: 24px; } }

.case-studies_carousel-lhc {
  justify-content: center; }
  @media screen and (max-width: 768px) {
    .case-studies_carousel-lhc {
      padding-bottom: 24px; } }

.case-studies_carousel-heading {
  padding-right: 36px;
  color: #1F2023; }

.case-studies_carousel-preheading {
  display: block;
  padding-bottom: 6px;
  color: #50396A;
  font-size: 18px;
  font-weight: 400; }

@media screen and (max-width: 768px) {
  .slick_case-studies {
    margin: 0 -24px; } }

@media screen and (max-width: 768px) {
  .slick_case-studies .slick-slide {
    padding: 0 24px; } }

.case-studies_carousel-item_wrapper {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  bottom: 0;
  padding-bottom: 24px; }

.case-studies_carousel-item {
  flex: 1 1 auto;
  left: 50%;
  width: calc(100% - 64px);
  max-width: 480px;
  text-align: center;
  transform: translateX(-50%); }
  @media screen and (max-width: 768px) {
    .case-studies_carousel-item {
      width: calc(100% - 48px); } }
  .case-studies_carousel-item:before, .case-studies_carousel-item:after {
    top: 24px;
    bottom: 24px;
    width: 48px;
    background: rgba(255, 255, 255, 0.48); }
    @media screen and (min-width: 769px) {
      .case-studies_carousel-item:before, .case-studies_carousel-item:after {
        content: ""; } }
  .case-studies_carousel-item:before {
    left: -48px;
    border-radius: 6px 0 0 6px; }
  .case-studies_carousel-item:after {
    right: -48px;
    border-radius: 0 6px 6px 0; }

.case-studies_carousel-item_content {
  flex: 1 1 auto;
  padding: 32px 32px 12px; }
  @media screen and (max-width: 768px) {
    .case-studies_carousel-item_content {
      padding: 0 0 12px; } }

.case-studies_carousel-item_client-name {
  color: #50396A;
  font-size: 18px;
  font-weight: 400; }

.case-studies_carousel-item_client-image {
  margin: 24px auto -56px;
  width: 96px;
  height: 96px;
  border-radius: 100%;
  border: 2px solid #FFF;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .case-studies_carousel-item_client-image {
      margin: 12px auto -48px; } }

.case-studies_carousel-controls {
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .case-studies_carousel-controls {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      position: absolute;
      left: 48px;
      right: 48px;
      bottom: 35.5px;
      z-index: 2;
      padding: 0; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN NAVIGATION (1ST LEVEL) -------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main-navigation {
  position: relative;
  z-index: 3; }
  @media screen and (min-width: 769px) {
    .main-navigation {
      flex: 1 1 auto;
      align-items: flex-end;
      padding-left: 32px; } }
  @media screen and (max-width: 768px) {
    .main-navigation {
      order: 1;
      width: 33%; } }

.main-navigation_list {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  font-family: "museo-sans", sans-serif; }
  @media screen and (min-width: 769px) {
    .main-navigation_list {
      flex-direction: row; } }
  @media screen and (max-width: 768px) {
    .main-navigation_list {
      flex-direction: column;
      position: fixed;
      top: 50px;
      left: 0;
      bottom: 0;
      z-index: 99999;
      padding: 24px;
      width: 100vw;
      background: #FFF;
      border-top: 1px solid #EEECE7;
      box-sizing: border-box;
      overflow: auto;
      transform: translate(-100%); } }

@media screen and (max-width: 920px) and (min-width: 769px) {
  .main-navigation_list-item {
    font-size: 16px; } }

@media screen and (min-width: 921px) {
  .main-navigation_list-item:not(:first-child) {
    padding-left: 32px; } }

@media screen and (max-width: 920px) and (min-width: 769px) {
  .main-navigation_list-item:not(:first-child) {
    padding-left: 24px; } }

@media screen and (max-width: 768px) {
  .main-navigation_list-item:not(:first-child) {
    padding-top: 18px; } }

.main-navigation_list-item.current-menu-item .main-navigation_list-item_link,
.main-navigation_list-item.current-menu-parent .main-navigation_list-item_link {
  color: #1F2023;
  font-weight: 700; }

.main-navigation_list-item.current-menu-item .main-navigation_list-item_link {
  cursor: default; }

.main-navigation_list-item_link {
  color: #727272; }
  @media screen and (min-width: 921px) {
    .main-navigation_list-item_link {
      text-transform: uppercase; } }
  @media screen and (min-width: 769px) {
    .main-navigation_list-item_link {
      font-size: 14px; }
      .main-navigation_list-item_link:hover, .main-navigation_list-item_link:focus {
        color: #1F2023; }
      .main-navigation_list-item_link:focus + .main-navigation_submenu {
        opacity: 1;
        visibility: visible; } }
  @media screen and (max-width: 768px) {
    .main-navigation_list-item_link {
      font-size: 18px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN NAVIGATION (2ND LEVEL) -------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main-navigation_submenu {
  list-style: none; }
  @media screen and (min-width: 769px) {
    .main-navigation_submenu {
      position: absolute;
      top: 56px;
      z-index: 2;
      margin-left: -24px;
      max-width: 300px;
      opacity: 0;
      visibility: hidden; } }
  @media screen and (max-width: 768px) {
    .main-navigation_submenu {
      padding-top: 9px; } }
  @media screen and (min-width: 769px) {
    .main-navigation_submenu:before {
      content: "";
      top: -6px;
      left: 24px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #e1ded5; } }

@media screen and (min-width: 769px) {
  .main-navigation_submenu-item:not(:first-child) {
    border-top: 1px solid #d5d0c3; } }

.main-navigation_submenu-item.current-menu-item .main-navigation_submenu-item_link,
.main-navigation_submenu-item.current-charity-ancestor .main-navigation_submenu-item_link {
  color: #1F2023; }

.main-navigation_submenu-item.current-menu-item .main-navigation_submenu-item_link {
  cursor: default; }

.main-navigation_submenu-item_link {
  display: block;
  color: #727272;
  font-family: "proxima-nova", sans-serif;
  font-size: 16px; }
  @media screen and (min-width: 769px) {
    .main-navigation_submenu-item_link {
      padding: 16px 24px;
      background: #e1ded5; }
      .main-navigation_submenu-item_link:hover, .main-navigation_submenu-item_link:focus {
        color: #1F2023; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MOBILE MAIN NAVIGATION (TRIGGER) --------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main-navigation_trigger {
  display: none; }
  .main-navigation_trigger button {
    background: transparent; }
  @media screen and (max-width: 768px) {
    .main-navigation_trigger {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      flex: 1 1 auto;
      justify-content: center;
      align-items: flex-start;
      padding: 0 24px;
      border-right: 1px solid #EEECE7; }
      .main-navigation_trigger button {
        padding-left: 30px; }
        .main-navigation_trigger button span {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          margin: 10px 2px;
          width: 18px;
          height: 2px;
          background: #1F2023; }
          .main-navigation_trigger button span:before, .main-navigation_trigger button span:after {
            content: "";
            left: 0;
            width: 18px;
            height: 2px;
            background: #1F2023; }
          .main-navigation_trigger button span:before {
            top: -6px; }
          .main-navigation_trigger button span:after {
            top: 6px; }
        .main-navigation_trigger button.open span {
          background: rgba(255, 255, 255, 0); }
          .main-navigation_trigger button.open span:before {
            top: 0;
            transform: rotate(45deg); }
          .main-navigation_trigger button.open span:after {
            top: 0;
            transform: rotate(-45deg); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MOBILE MAIN NAVIGATION (OPEN) ------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js_main-navigation_open {
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .js_main-navigation_open .main-navigation_list {
      transform: translateX(0); } }
  @media screen and (max-width: 768px) {
    .js_main-navigation_open .main-navigation_trigger button span {
      background: rgba(255, 255, 255, 0); }
      .js_main-navigation_open .main-navigation_trigger button span:before {
        top: 0;
        transform: rotate(45deg); }
      .js_main-navigation_open .main-navigation_trigger button span:after {
        top: 0;
        transform: rotate(-45deg); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIALS NAVIGATION ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.socials-navigation {
  align-items: center;
  margin-top: 96px;
  padding-top: 48px;
  border-top: 1px solid #DAD6CD; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .socials-navigation {
      margin-top: 48px; } }
  @media screen and (max-width: 768px) {
    .socials-navigation {
      margin-top: 24px;
      padding-top: 24px; } }

.socials-navigation_heading {
  padding-right: 32px; }
  @media screen and (max-width: 768px) {
    .socials-navigation_heading {
      flex: 1 1 auto; } }

.socials-navigation_list-item {
  list-style: none; }
  .socials-navigation_list-item:not(:first-child) {
    padding-left: 12px; }
    @media screen and (max-width: 768px) {
      .socials-navigation_list-item:not(:first-child) {
        padding-left: 6px; } }

.socials-navigation_list-item_link {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid #1F2023;
  border-radius: 100%; }
  @media screen and (max-width: 768px) {
    .socials-navigation_list-item_link {
      width: 32px;
      height: 32px; } }
  .socials-navigation_list-item_link svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: #1F2023; }
    @media screen and (max-width: 768px) {
      .socials-navigation_list-item_link svg {
        width: 12px;
        height: 12px; } }
  @media screen and (min-width: 769px) {
    .socials-navigation_list-item_link.hover_twitter:hover, .socials-navigation_list-item_link.hover_twitter:focus {
      border: 2px solid #4099FF; }
      .socials-navigation_list-item_link.hover_twitter:hover svg, .socials-navigation_list-item_link.hover_twitter:focus svg {
        fill: #4099FF; }
    .socials-navigation_list-item_link.hover_linkedin:hover, .socials-navigation_list-item_link.hover_linkedin:focus {
      border: 2px solid #0077B5; }
      .socials-navigation_list-item_link.hover_linkedin:hover svg, .socials-navigation_list-item_link.hover_linkedin:focus svg {
        fill: #0077B5; }
    .socials-navigation_list-item_link.hover_facebook:hover, .socials-navigation_list-item_link.hover_facebook:focus {
      border: 2px solid #3B5998; }
      .socials-navigation_list-item_link.hover_facebook:hover svg, .socials-navigation_list-item_link.hover_facebook:focus svg {
        fill: #3B5998; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography {
  color: #727272; }
  .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6, .typography p, .typography ul, .typography ol {
    padding-top: 1.5em; }
    @media screen and (max-width: 768px) {
      .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6, .typography p, .typography ul, .typography ol {
        padding-top: 1em; } }
    .typography h1:first-child, .typography h2:first-child, .typography h3:first-child, .typography h4:first-child, .typography h5:first-child, .typography h6:first-child, .typography p:first-child, .typography ul:first-child, .typography ol:first-child {
      padding-top: 0; }
  .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6, .typography strong {
    color: #1F2023; }
  .typography ul {
    list-style: none; }
    @media screen and (min-width: 769px) {
      .typography ul {
        margin-top: -.5em; } }
    .typography ul > li {
      position: relative;
      z-index: 1;
      padding-left: 24px;
      line-height: 1.4; }
      .typography ul > li:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        margin: 10px 12px 0 0;
        width: 7px;
        height: 7px;
        background: #1F2023;
        border-radius: 100%; }
        @media screen and (max-width: 768px) {
          .typography ul > li:before {
            margin-top: 7px; } }
      @media screen and (min-width: 769px) {
        .typography ul > li > ul {
          padding: 1em 0 .5em !important; } }
  .typography hr {
    padding: 0;
    margin: 1.5em 0 .5em;
    height: 1px;
    background-color: #DAD6CD;
    border: none; }
    .typography hr:first-child {
      margin-top: 0; }
  .typography a:not(.btn):not(.link) {
    text-decoration: none;
    word-break: break-word;
    border-bottom: 1px solid #1F2023; }
    @media screen and (min-width: 769px) {
      .typography a:not(.btn):not(.link):hover, .typography a:not(.btn):not(.link):focus {
        color: #090a0b;
        border-bottom: 1px solid transparent; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY (INVERTED) -------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typo_inverted {
  color: rgba(255, 255, 255, 0.64); }
  .typo_inverted h1, .typo_inverted h2, .typo_inverted h3, .typo_inverted h4, .typo_inverted h5, .typo_inverted h6, .typo_inverted strong {
    color: #FFF; }
  .typo_inverted hr {
    background-color: #FFF; }
  .typo_inverted a:not(.btn):not(.link) {
    color: #FFF;
    border-bottom: 1px solid #FFF; }
    @media screen and (min-width: 769px) {
      .typo_inverted a:not(.btn):not(.link):hover, .typo_inverted a:not(.btn):not(.link):focus {
        color: #FFF;
        border-bottom: 1px solid transparent; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY (COMPRESSED) ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_compressed {
  line-height: 1.5; }
  .typography_compressed h1, .typography_compressed h2, .typography_compressed h3, .typography_compressed h4, .typography_compressed h5, .typography_compressed h6, .typography_compressed p, .typography_compressed ul, .typography_compressed ol {
    padding-top: 1em; }
    @media screen and (max-width: 768px) {
      .typography_compressed h1, .typography_compressed h2, .typography_compressed h3, .typography_compressed h4, .typography_compressed h5, .typography_compressed h6, .typography_compressed p, .typography_compressed ul, .typography_compressed ol {
        padding-top: .75em; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY (BLUE) ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_02-01 h1, .typography_02-01 h2, .typography_02-01 h3, .typography_02-01 h4, .typography_02-01 h5, .typography_02-01 h6, .typography_02-01 strong {
  color: #50396A; }

.typography_02-01 ul > li:before {
  background: #50396A; }

.typography_02-01 a:not(.btn):not(.link) {
  color: #50396A;
  border-bottom: 1px solid #50396A; }
  @media screen and (min-width: 769px) {
    .typography_02-01 a:not(.btn):not(.link):hover, .typography_02-01 a:not(.btn):not(.link):focus {
      color: #39294c; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY (PURPLE) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_02-02 h1, .typography_02-02 h2, .typography_02-02 h3, .typography_02-02 h4, .typography_02-02 h5, .typography_02-02 h6, .typography_02-02 strong {
  color: #8F3A41; }

.typography_02-02 ul > li:before {
  background: #8F3A41; }

.typography_02-02 a:not(.btn):not(.link) {
  color: #8F3A41;
  border-bottom: 1px solid #8F3A41; }
  @media screen and (min-width: 769px) {
    .typography_02-02 a:not(.btn):not(.link):hover, .typography_02-02 a:not(.btn):not(.link):focus {
      color: #6e2d32; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY (ORANGE) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_02-03 h1, .typography_02-03 h2, .typography_02-03 h3, .typography_02-03 h4, .typography_02-03 h5, .typography_02-03 h6, .typography_02-03 strong {
  color: #5B795E; }

.typography_02-03 ul > li:before {
  background: #5B795E; }

.typography_02-03 a:not(.btn):not(.link) {
  color: #5B795E;
  border-bottom: 1px solid #5B795E; }
  @media screen and (min-width: 769px) {
    .typography_02-03 a:not(.btn):not(.link):hover, .typography_02-03 a:not(.btn):not(.link):focus {
      color: #475f4a; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY (RED) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_02-04 h1, .typography_02-04 h2, .typography_02-04 h3, .typography_02-04 h4, .typography_02-04 h5, .typography_02-04 h6, .typography_02-04 strong {
  color: #CE6A57; }

.typography_02-04 ul > li:before {
  background: #CE6A57; }

.typography_02-04 a:not(.btn):not(.link) {
  color: #CE6A57;
  border-bottom: 1px solid #CE6A57; }
  @media screen and (min-width: 769px) {
    .typography_02-04 a:not(.btn):not(.link):hover, .typography_02-04 a:not(.btn):not(.link):focus {
      color: #bf4d38; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE (TYPE 1) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.page_type-01 {
  padding: 96px;
  width: calc(100% - 192px); }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .page_type-01 {
      padding: 48px;
      width: calc(100% - 96px); } }
  @media screen and (max-width: 768px) {
    .page_type-01 {
      padding: 24px;
      width: calc(100% - 48px); } }
  .page_type-01 > .center_content > .grid:not(:first-child),
  .page_type-01 > .center_content > .pb_block:not(:first-child) {
    padding-top: 48px; }
    @media screen and (max-width: 1024px) {
      .page_type-01 > .center_content > .grid:not(:first-child),
      .page_type-01 > .center_content > .pb_block:not(:first-child) {
        padding-top: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 768px) {
  .pb_block .pb_block_mediatext:not(:first-child) {
    padding-top: 24px; } }

/* LAYOUT */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* WRAPPER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wrapper {
  flex: 1 1 auto;
  margin-top: 108px; }
  @media screen and (max-width: 920px) and (min-width: 769px) {
    .wrapper {
      margin-top: 94px; } }
  @media screen and (max-width: 768px) {
    .wrapper {
      margin-top: 51px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CENTER CONTENT --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.center_content {
  margin: 0 auto;
  width: 100%; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN CONTAINER --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* SITE TITLE ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.site_title {
  padding: 12px;
  color: #FFF;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  background: #50396A; }
  .site_title:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.12); }
  .site_title span {
    position: relative;
    z-index: 2; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CONTACT PAGE ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.contact-page-builder .wSmall {
  max-width: none; }

.contact-form:not(:first-child) {
  margin-top: 96px; }
  @media screen and (max-width: 1300px) and (min-width: 769px) {
    .contact-form:not(:first-child) {
      margin-top: 48px; } }
  @media screen and (max-width: 768px) {
    .contact-form:not(:first-child) {
      margin-top: 24px; } }

@media screen and (min-width: 769px) {
  .contact-rhc {
    margin-top: -32px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #FFF;
  /*
	.center_content {
		@extend .flex_row;
		width:100%; max-width:calc(1240px + 96px);

		@media screen and (min-width:769px)
		{
			align-items:center;
		}
		@media screen and (max-width:768px)
		{
			position:relative;
			z-index:1;
			justify-content:space-between;
		}
	}
	*/ }
  @media screen and (min-width: 921px) {
    .header {
      padding: 24px; } }
  @media screen and (max-width: 920px) and (min-width: 769px) {
    .header {
      padding: 18px; } }
  @media screen and (min-width: 769px) {
    .header {
      align-items: center; } }
  @media screen and (max-width: 768px) {
    .header {
      justify-content: space-between;
      min-height: 52px; } }
  .header:after {
    content: "";
    left: 0;
    right: 0;
    bottom: -6px;
    height: 6px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0; }
  .header.is-sticky:after {
    opacity: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo {
  position: relative;
  z-index: 3;
  display: block;
  margin-bottom: -38px;
  width: auto;
  height: 98px; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .logo {
      margin-bottom: 0;
      height: 48px; } }
  @media screen and (max-width: 768px) {
    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 32px;
      height: auto;
      transform: translate(-50%, -50%); } }
  .logo img {
    display: block;
    position: relative;
    z-index: 4;
    width: auto;
    height: 98px; }
    @media screen and (max-width: 1024px) and (min-width: 769px) {
      .logo img {
        height: 48px; } }
    @media screen and (max-width: 768px) {
      .logo img {
        width: 32px;
        height: auto; } }
  .logo svg {
    display: block;
    position: absolute;
    top: 83px;
    left: 50%;
    z-index: 3;
    width: 792px;
    height: 66px;
    fill: #FFF;
    transform: translateX(-50%); }
    @media screen and (max-width: 1024px) {
      .logo svg {
        display: none; } }

/*
.logo,
.logo img {



	@media screen and (max-width:920px) and (min-width:769px)
	{
		height:48px;
	}
	@media screen and (max-width:768px)
	{
		width:32px; height:auto;
	}
}
*/
/* ------------------------------------------------------------------------------------------------------------------------ */
/* CTA -------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (min-width: 921px) {
  .header_cta {
    margin-left: 32px; } }

@media screen and (max-width: 920px) and (min-width: 769px) {
  .header_cta {
    margin-left: 24px;
    font-size: 16px; } }

@media screen and (max-width: 768px) {
  .header_cta {
    order: 2;
    padding: 16px 24px;
    width: 33%;
    text-align: center;
    border-radius: 0;
    box-sizing: border-box; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.footer {
  margin-top: auto;
  padding: 96px;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.64);
  background: #452E5E; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .footer {
      padding: 48px; } }
  @media screen and (max-width: 768px) {
    .footer {
      padding: 24px; } }
  @media screen and (max-width: 768px) {
    .footer .center_content > .grid .column:not(:first-child) {
      padding-top: 24px; } }
  .footer .center_content > .grid + .grid {
    align-items: flex-end;
    padding-top: 48px; }
    @media screen and (max-width: 768px) {
      .footer .center_content > .grid + .grid {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid rgba(255, 255, 255, 0.12); } }
  .footer .typography p + p {
    padding-top: .75em; }
  .footer .footer_link {
    color: rgba(255, 255, 255, 0.64);
    word-break: break-word; }
    @media screen and (min-width: 769px) {
      .footer .footer_link:hover, .footer .footer_link:focus {
        color: #FFF; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER NAVIGATION ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.footer-navigation_list {
  list-style: none; }
  @media screen and (max-width: 920px) {
    .footer-navigation_list {
      flex-wrap: wrap; } }

@media screen and (max-width: 920px) {
  .footer-navigation_list-item {
    width: 50%; } }

@media screen and (min-width: 921px) {
  .footer-navigation_list-item:not(:first-child) {
    padding-left: 24px; } }

.footer-navigation_list-item_link {
  color: rgba(255, 255, 255, 0.64);
  border-bottom: 1px solid rgba(255, 255, 255, 0.64); }
  @media screen and (min-width: 769px) {
    .footer-navigation_list-item_link:hover, .footer-navigation_list-item_link:focus {
      color: #FFF;
      border-bottom: 1px solid transparent; } }
