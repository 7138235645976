/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE MAP (TYPE 1) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$map_ratio : ((600 / 800) * 100);

.gmap_type-01 {
	@extend .relative;
	padding-top:$map_ratio + 0%;
	background:#FFF;
	border-radius:6px;
	box-shadow:0 3px 12px 0 rgba(#000, .12);

	.gmap {
		@extend .full_size;
	}
}
