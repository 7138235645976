/* ------------------------------------------------------------------------------------------------------------------------ */
/* THEME COLORS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- PRIMARY --- */
$color_theme_01-01 : #1F2023;  // Black(ish)
$color_theme_01-02 : #EEECE7;  // White(ish)
$color_theme_01-03 : #727272;  // Gray
$color_theme_01-04 : #DAD6CD;  // Light Gray

/* --- SECONDARY --- */
$color_theme_02-01 : #50396A;  // Purple
$color_theme_02-02 : #8F3A41;  // Red
$color_theme_02-03 : #5B795E;  // Green
$color_theme_02-04 : #CE6A57;  // Orange
$color_theme_02-05 : #464B77;  // Blue

/* --- TERTIARY --- */
$color_theme_03-01 : #452E5E;  // Darker Purple
$color_theme_03-02 : #822F36;  // Darker Red
$color_theme_03-03 : #4B6B4E;  // Darker Green
$color_theme_03-04 : #C25C48;  // Darker Orange
$color_theme_03-05 : #3A3F6C;  // Darker Blue

/* --- SYSTEM COLORS --- */
$color_error  : #D91E18;
$color_alert  : #F89406;
$color_valid  : #00B16A;
$color_update : #1E8BC3;

/* --- SOCIAL COLORS --- */
$color_facebook  : #3B5998;
$color_twitter   : #4099FF;
$color_linkedin  : #0077B5;
$color_instagram : #125688;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT FAMILIES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$font_family_01 : 'proxima-nova', sans-serif;
$font_family_02 : 'museo-sans', sans-serif;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT SIZES ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$font_size_small : 14px;
$font_size_base  : 18px;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LINE HEIGHTS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$line_height_small : 1.4;
$line_height_base  : 1.6;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAYOUT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$site_width_base    : 1240px;
$site_width_medium  : 980px;
$site_width_small   : 720px;
$site_width_xsmall  : 440px;
$site_width_xxsmall : 320px;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FLUID TEXT ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}
