/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.page {
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE (TYPE 1) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.page_type-01 {
	padding:96px;
	width:calc(100% - 192px);

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding:48px;
		width:calc(100% - 96px);
	}
	@media screen and (max-width:768px)
	{
		padding:24px;
		width:calc(100% - 48px);
	}

	& > .center_content > .grid:not(:first-child),
	& > .center_content > .pb_block:not(:first-child) {
		padding-top:48px;

		@media screen and (max-width:1024px)
		{
			padding-top:24px;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGE BUILDER ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pb_block {

	.pb_block_mediatext:not(:first-child) {

		@media screen and (max-width:768px)
		{
			padding-top:24px;
		}
	}
}
