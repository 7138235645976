/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero {
	@extend .relative;
	padding:0 96px;
	background:$color_theme_02-01;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding:0 48px;
	}
	@media screen and (max-width:768px)
	{
		padding:0 24px;
	}

	.center_content {
		position:relative;
		z-index:2;
		padding:96px 0;

		@media screen and (max-width:1024px) and (min-width:769px)
		{
			padding:48px 0;
		}
		@media screen and (max-width:768px)
		{
			padding:24px 0;
		}
	}
}

	.hero_heading {
		color:#FFF;
		width:55%; max-width:$site_width_small;

		br {

			@media screen and (max-width:768px)
			{
				display:none;
			}
		}
	}
		.hero_preheading {
			display:block;
			padding-bottom:24px;
			line-height:$line_height_small;
			color:$color_theme_01-02;
			font-size:$font_size_base;
			font-weight:400;

			@media screen and (max-width:768px)
			{
				padding-bottom:6px;
			}
		}

	.hero_background-wrapper {
		position:absolute;
		top:0; right:0;
		z-index:1;
		width:50vw; max-width:660px; height:50vw; max-height:660px;
		overflow:hidden;

		@media screen and (max-width:768px)
		{
			display:none;
		}
	}
		.hero_background {
			position:absolute;
			top:0; right:0;
			z-index:1;
			width:calc(100vw - 192px); max-width:1320px; height:calc(100vw - 192px); max-height:1320px;
			border-radius:100%;
			overflow:hidden;
			transform:translate(50%, -50%);

			@media screen and (max-width:768px)
			{
				left:50%; right:auto;
				width:calc(100vw + 192px); height:100vw;
				transform:translateX(-50%);
				border-radius:0 0 100% 100%;
				mix-blend-mode:luminosity;
			}
		}
			.hero_background-image {
				@extend .absolute;
				left:-50%; bottom:0;
				width:100%;
				transform:translateX(25%);

				@media screen and (max-width:768px)
				{
					top:0; left:0; right:0;
					height:100%;
					transform:none;

					&:before {
						content:"";
						position:absolute;
						top:0; left:0; right:0; bottom:0;
						z-index:3;
						background:linear-gradient(to bottom, rgba($color_theme_02-01, 1) 0%, rgba($color_theme_02-01, 0) 100%);
					}
				}

				img {
					object-fit:cover; font-family:'object-fit:cover;';

					@media screen and (max-width:768px)
					{
						position:absolute;
						top:0; left:0; right:0; bottom:0;
						z-index:2;
						width:100%; height:100%;
						filter:grayscale(100%);
					}
				}
			}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (HOMEPAGE) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero_homepage {
	overflow:hidden;
}

	.hero_homepage-boxes {
		padding-top:48px;

		@media screen and (max-width:768px)
		{
			padding-top:24px;
		}

		& > .grid .column:not(:first-child) {

			@media screen and (max-width:768px)
			{
				padding-top:6px;
			}
		}
	}
		.hero_homepage-boxes_item {
			line-height:$line_height_small;
			text-align:center;
		}
			.hero_homepage-boxes_item-heading {
				@extend .heading_type-01;
				color:$color_theme_01-01;
				font-size:18px;
			}
			.hero_homepage-boxes_item-subheading {
				padding-top:.75em;
			}
			.hero_homepage-boxes_item-btn {
				margin-top:auto; padding-top:1.25em;
			}

	.hero_homepage-background {
		background:$color_theme_03-01;
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO (STANDARD) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero_standard {
}

.hero_mask {
	overflow:hidden;

	.hero_heading {
		@extend .flex_column;
		max-width:$site_width_xsmall;
	}
		.hero_preheading {
			order:2;
			padding-top:24px; padding-bottom:0;

			@media screen and (max-width:768px)
			{
				padding-top:6px;
			}
		}

	.hero_background-wrapper {
		top:50%;
		max-width:$site_width_medium; max-height:$site_width_medium;
		transform:translateY(-50%);
	}
		.hero_background {
			max-width:1960px; max-height:1960px;
			transform:translate(50%, -25%);
		}
			.hero_background-image {
				top:0; left:0; right:auto; bottom:auto;
				width:100%; height:50%;
				transform:translate(-25%, 50%);

				img {
					@extend .full_size;
				}
			}
}
