/* ------------------------------------------------------------------------------------------------------------------------ */
/* GRANTS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.grants {
	padding:0 96px;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding:48px;
	}
	@media screen and (max-width:768px)
	{
		padding:24px;
	}
}

	.grants_intro {
		padding-top:96px;

		@media screen and (max-width:1024px) and (min-width:769px)
		{
			padding-top:48px;
		}
		@media screen and (max-width:768px)
		{
			padding-top:0;
		}
	}

	.grants_list {
		margin-top:-12px !important; padding:48px 0 96px;

		@media screen and (max-width:1024px) and (min-width:769px)
		{
			padding:48px 0;
		}
		@media screen and (max-width:768px)
		{
			padding:24px 0 0;
		}
	}
		.grants_list-item {
			margin-top:48px;

			@media screen and (max-width:768px)
			{
				margin-top:24px;
			}
		}
			.grants_list-item_heading {
				color:$color_theme_01-01;
			}
			.grants_list-item_subheading {
				padding-top:6px;
			}
			.grants_list-item_content {
				padding-top:18px;
			}
			.grants_list-item_link {
				@extend .flex_column;
				margin-top:auto; padding-top:24px;

				a {
					text-align:center;
				}
			}

	.grants_list-nav {
		margin-top:-12px;

		ul {
			display:flex;
			flex-direction:row;
			flex-wrap:wrap;
			list-style:none;

			@media screen and (max-width:768px)
			{
				flex-direction:column;
			}

			li {
				padding-top:12px;

				&:not(:last-child) {

					@media screen and (min-width:769px)
					{
						padding-right:12px;
					}
				}

				.btn {

					@media screen and (max-width:768px)
					{
						width:100%;
					}
				}
			}
		}
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GRANT INFO BOX --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.grant_info-box {
	@extend .relative;
	flex:0 1 auto;
	overflow:hidden;

	@media screen and (max-width:768px)
	{
		margin-top:24px;
	}

	&:before {
		@extend .absolute;
		content:"";
		top:-48px; right:64px;
		width:440px; height:360px;
		border-radius:100%;
		background:rgba(#000, .15);
		mix-blend-mode:overlay;
	}
}
	.grant_info-box_content {
		position:relative;
		z-index:2;

		.typography p {
			color:#FFF;
		}
	}
	.grant_info-box_links {
	}
		.grant_info-box_links-wrapper {
			margin:24px -32px 0; padding:24px 32px 0;
			color:#FFF;
			border-top:1px solid rgba(#FFF, .12);

			@media screen and (max-width:768px)
			{
				margin:24px -24px 0; padding:24px 24px 0;
			}

			.btn {
				display:block;
				margin-top:18px;
				text-align:center;
			}
		}
