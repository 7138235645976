/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pagination {
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PAGINATION (TYPE 1) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.pagination_type-01 {
	@extend .flex_row;
	justify-content:space-between;
	margin-top:48px; padding-top:24px;
	border-top:1px solid $color_theme_01-04;

	@media screen and (max-width:768px)
	{
		margin-top:24px;
	}

	.page-link {
		@extend .animate;
		@extend .flex_row;
		align-items:center;
		line-height:1;

		span {
			margin-top:-2px;
		}
		svg {
			@extend .animate;
			display:block;
			padding-left:12px;
			width:18px; height:18px;
			fill:$color_theme_01-01;
		}

		&.page-link_prev svg {
			transform:rotate(180deg);
		}

		&.disabled {
			opacity:.32;

			svg {
				color:$color_theme_01-03;
			}
		}

		@media screen and (min-width:769px)
		{
			&:not(.disabled):hover,
			&:not(.disabled):focus {
				color:$color_theme_02-01;

				svg {
					fill:$color_theme_02-01;
				}
			}
		}
	}
}
