/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.footer {
	margin-top:auto; padding:96px;
	line-height:1.4;
	color:rgba(#FFF, .64);
	background:$color_theme_03-01;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding:48px;
	}
	@media screen and (max-width:768px)
	{
		padding:24px;
	}

	.center_content > .grid {

		.column:not(:first-child) {

			@media screen and (max-width:768px)
			{
				padding-top:24px;
			}
		}

	 	& + .grid {
			align-items:flex-end;
			padding-top:48px;

			@media screen and (max-width:768px)
			{
				margin-top:24px; padding-top:24px;
				border-top:1px solid rgba(#FFF, .12);
			}
		}
	}

	.typography {

		p + p {
			padding-top:.75em;
		}
	}

	.footer_link {
		@extend .animate;
		color:rgba(#FFF, .64);
		word-break:break-word;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:#FFF;
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER NAVIGATION ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.footer-navigation {
	@extend .flex_column;
}
	.footer-navigation_list {
		@extend .flex_row;
		list-style:none;

		@media screen and (max-width:920px)
		{
			flex-wrap:wrap;
		}
	}
		.footer-navigation_list-item {

			@media screen and (max-width:920px)
			{
				width:50%;
			}

			&:not(:first-child) {

				@media screen and (min-width:921px)
				{
					padding-left:24px;
				}
			}
		}
			.footer-navigation_list-item_link {
				@extend .animate;
				color:rgba(#FFF, .64);
				border-bottom:1px solid rgba(#FFF, .64);

				@media screen and (min-width:769px)
				{
					&:hover,
					&:focus {
						color:#FFF;
						border-bottom:1px solid transparent;
					}
				}
			}
