/* ------------------------------------------------------------------------------------------------------------------------ */
/* WRAPPER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wrapper {
	@extend .flex_column;
	flex:1 1 auto;
	margin-top:108px;

	@media screen and (max-width:920px) and (min-width:769px)
	{
		margin-top:94px;
	}
	@media screen and (max-width:768px)
	{
		margin-top:51px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CENTER CONTENT --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.center_content {
	margin:0 auto;
	width:100%;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN CONTAINER --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main_container {
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SITE TITLE ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.site_title {
	@extend .relative;
	padding:12px;
	color:#FFF;
	font-weight:600;
	text-align:center;
	text-transform:uppercase;
	background:$color_theme_02-01;

	&:before {
		content:"";
		position:absolute;
		top:0; left:0; right:0; bottom:0;
		z-index:1;
		background:rgba(#000, .12);
	}

	span {
		position:relative;
		z-index:2;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CONTACT PAGE ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.contact-page-builder {

	.wSmall {
		max-width:none;
	}
}

.contact-form {

	&:not(:first-child) {
		margin-top:96px;

		@media screen and (max-width:1300px) and (min-width:769px)
		{
			margin-top:48px;
		}
		@media screen and (max-width:768px)
		{
			margin-top:24px;
		}
	}
}

.contact-rhc {

	@media screen and (min-width:769px)
	{
		margin-top:-32px;
	}
}
