/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX -------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box {
	display:flex;
	flex-direction:column;
	flex-wrap:nowrap;
	flex:1 1 auto;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX (TYPE 1 & 2) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box_type-01,
.box_type-02 {
	padding:32px;

	@media screen and (max-width:768px)
	{
		padding:24px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX (TYPE 1) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box_type-01 {
	background:#FFF;
	border-radius:6px;
	box-shadow:0 3px 12px 0 rgba(#000, .12);
}
