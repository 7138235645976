/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARTICLE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.article {
}

	.article_heading {
		color:#FFF;
		width:100%; max-width:$site_width_small;
	}

	.article_meta {
		padding:0 96px;
		background:$color_theme_02-01;

		@media screen and (max-width:1024px) and (min-width:769px)
		{
			padding:0 48px;
		}
		@media screen and (max-width:768px)
		{
			padding:0 24px;
		}

		.center_content {
			padding:96px 0;

			@media screen and (max-width:1024px) and (min-width:769px)
			{
				padding:48px 0;
			}
			@media screen and (max-width:768px)
			{
				padding:24px 0;
			}
		}
	}
		.article_meta-item {
			padding-top:24px;
			color:rgba(#FFF, .64);
			font-size:$font_size_base;

			@media screen and (max-width:768px)
			{
				padding-top:12px;
			}
		}
			.article_meta-item_date {
				color:$color_theme_01-02;
			}
			.article_meta-item_categories {
				display:inline;
				list-style:none;

				& > li {
					display:inline;

					& > a {
						@extend .animate;
						color:$color_theme_01-02;
						font-weight:500;
						text-decoration:underline;

						@media screen and (min-width:769px)
						{
							&:hover,
							&:focus {
								color:#FFF;
								text-decoration:none;
							}
						}
					}
				}
			}
