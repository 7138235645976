/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIALS NAVIGATION ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.socials-navigation {
	@extend .flex_row;
	align-items:center;
	margin-top:96px; padding-top:48px;
	border-top:1px solid $color_theme_01-04;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		margin-top:48px;
	}
	@media screen and (max-width:768px)
	{
		margin-top:24px; padding-top:24px;
	}
}

	.socials-navigation_heading {
		padding-right:32px;

		@media screen and (max-width:768px)
		{
			flex:1 1 auto;
		}
	}

	.socials-navigation_list {
		@extend .flex_row;
	}
		.socials-navigation_list-item {
			list-style:none;

			&:not(:first-child) {
				padding-left:12px;

				@media screen and (max-width:768px)
				{
					padding-left:6px;
				}
			}
		}
			.socials-navigation_list-item_link {
				@extend .animate;
				display:flex;
				flex-direction:column;
				flex-wrap:wrap;
				align-items:center;
				justify-content:center;
				width:40px; height:40px;
				border:2px solid $color_theme_01-01;
				border-radius:100%;

				@media screen and (max-width:768px)
				{
					width:32px; height:32px;
				}

				svg {
					@extend .animate;
					display:block;
					width:16px; height:16px;
					fill:$color_theme_01-01;

					@media screen and (max-width:768px)
					{
						width:12px; height:12px;
					}
				}

				@media screen and (min-width:769px)
				{
					&.hover_twitter:hover,
					&.hover_twitter:focus {
						border:2px solid $color_twitter;

						svg {
							fill:$color_twitter;
						}
					}
					&.hover_linkedin:hover,
					&.hover_linkedin:focus {
						border:2px solid $color_linkedin;

						svg {
							fill:$color_linkedin;
						}
					}
					&.hover_facebook:hover,
					&.hover_facebook:focus {
						border:2px solid $color_facebook;

						svg {
							fill:$color_facebook;
						}
					}
				}
			}
