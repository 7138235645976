.controls {
	@extend .flex_row;

	button + button {
		margin-left:24px;
	}
}
	.controls_prev,
	.controls_next {
		width:24px; height:24px;
		background:transparent;
		cursor:pointer;

		svg {
			@extend .animate;
			width:24px; height:24px;
			fill:$color_theme_01-01;
		}

		@media screen and (min-width:769px)
		{
			&:hover svg,
			&:focus svg {
				fill:$color_theme_02-01;
			}
		}
	}
	.controls_prev {
		transform:rotate(180deg);
	}
