/* ------------------------------------------------------------------------------------------------------------------------ */
/* COOKIE NOTIFICATION ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.cookie_notification {
	position:fixed;
	right:24px; bottom:24px;
	z-index:99;
	padding:32px;
	width:100%; max-width:200px;
	line-height:$line_height_small;
	font-size:$font_size_small;
	text-align:center;
	background:#FFF;
	border-radius:6px;
	box-shadow:0 3px 12px 0 rgba(#000, .12);

	@media screen and (max-width:768px)
	{
		display:flex;
		flex-direction:row;
		flex-wrap:wrap;
		align-items:center;
		left:12px; right:12px; bottom:12px;
		padding:24px;
		width:auto; max-width:none;
		text-align:left;
		box-shadow:0 3px 12px 0 rgba(#000, .12);
	}

	svg {
		display:block;
		margin:0 auto; padding-bottom:24px;
		width:48px; height:48px;
		fill:$color_theme_02-01;

		@media screen and (max-width:768px)
		{
			margin:0; padding:0 18px 0 0;
		}
	}

	p > a {
		@extend .animate;
		color:$color_theme_01-01;
		text-decoration:underline;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				text-decoration:none;
			}
		}
		@media screen and (max-width:768px)
		{
			flex:1;
			color:$color_theme_02-01;
		}
	}

	.cookie_close {
		@extend .animate;

		&.anchor {
			display:inline-block;
			margin:12px auto 0;
			color:$color_theme_02-01;
			text-decoration:none;

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:darken($color_theme_02-01, 9%);
				}
			}
			@media screen and (max-width:768px)
			{
				flex:1 0 auto;
				margin:18px -24px -24px; padding:18px 0 24px;
				width:100%;
				text-align:center;
				border-top:1px solid $color_theme_01-02;
			}
		}

		&.icon {
			@extend .absolute;
			top:16px; right:16px;
			padding:4px;
			width:16px; height:16px;
			opacity:.48;

			svg {
				display:block;
				width:16px; height:16px;
				fill:$color_theme_02-01;

				@media screen and (max-width:768px)
				{
					width:12px; height:12px;
				}
			}

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					opacity:1;
				}
			}
			@media screen and (max-width:768px)
			{
				top:0; right:0;
				padding:12px;
				width:12px; height:12px;
			}
		}
	}
}
