/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAZYLOAD --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.lazyload_wrapper {
	@extend .relative;

	picture,
	img {
		@extend .relative;
		@extend .full_width;
		opacity:0;

		&.lazyloaded {
			opacity:1;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.no-js {

	.lazyload_wrapper picture,
	.lazyload_wrapper img {
		opacity:1;
	}
}
