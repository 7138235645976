/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARCHIVE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
section.archive {
	padding:96px;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding:48px;
	}
	@media screen and (max-width:768px)
	{
		padding:24px;
	}

	.grid {
		margin-top:-48px;

		@media screen and (max-width:768px)
		{
			margin-top:-24px;
		}
	}
}

	.archive_heading {
		padding-bottom:48px;
		text-align:center;

		@media screen and (max-width:768px)
		{
			padding-bottom:12px;
		}
	}

	.archive_item {
		@extend .flex_column;
		flex:1 1 auto;
		padding-top:48px;

		@media screen and (max-width:768px)
		{
			padding-top:24px;
		}
	}
		.archive_item-thumb {
			display:block;
			background:$color_theme_02-01;

			img {
				@extend .animate;
				aspect-ratio:attr(width) / attr(height);
			}

			@media screen and (min-width:769px)
			{
				&:hover img,
				&:focus img {
					opacity:.72;
				}
			}
		}
		.archive_item-date {
			padding-top:18px;
		}
		.archive_item-heading {
			padding-top:12px;
			color:$color_theme_01-01;
			font-size:$font_size_base;
			text-transform:uppercase;

			a {
				@extend .animate;

				@media screen and (min-width:769px)
				{
					&:hover,
					&:focus {
						color:$color_theme_02-01;
					}
				}
			}
		}
		.archive_item-excerpt {
			padding-top:12px;
			line-height:$line_height_small;
		}
		.archive_item-link {
			@extend .animate;
			align-self:flex-start;
			margin-top:auto; padding-top:18px;
			font-size:$font_size_small;
			font-weight:700;
			text-transform:uppercase;
			text-decoration:underline;

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:$color_theme_02-01;
					text-decoration:none;
				}
			}
		}

	.archive_view-all {
		@extend .flex_column;
		align-items:center;
		padding-top:48px;

		@media screen and (max-width:768px)
		{
			flex-direction:row;
			justify-content:flex-end;
			align-items:center;
			padding-top:24px;
		}
	}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARCHIVE (CAROUSEL) ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.archive_carousel {

	.slick-list {
		margin-left:-24px; margin-right:-24px;

		@media screen and (min-width:769px)
		{
			margin-top:-48px;
		}
		@media screen and (max-width:768px)
		{
			margin-top:-12px;
		}

		.slick-track {
			@extend .flex_row;
			margin:0;
		}
			.slick-slide {
				@extend .flex_column;
				padding:0 24px;
				height:auto !important;

				& > div {
					@extend .flex_column;
					flex:1 1 auto;

					& > .column {
						display:flex !important;
						flex-direction:column;
						flex-wrap:nowrap;
						flex:1 1 auto;
					}
				}
			}
	}
}

	.archive_carousel-controls {
		display:none;

		@media screen and (max-width:768px)
		{
			display:block;
			flex:1 1 auto;
		}
	}
