.form {

	a {
		@extend .animate;
		text-decoration:underline;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				text-decoration:none;
			}
		}
	}
}

.small_info {
	padding:24px 24px 0;
	line-height:$line_height_small;
	font-size:$font_size_small;

	@media screen and (max-width:768px)
	{
		margin:0 -24px; padding:24px;
		border-bottom:1px solid $color_theme_01-04;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (TYPE 1) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form_type_1 {
	margin-top:6px;

	@media screen and (max-width:768px)
	{
		margin-top:0;
	}

	a {
		color:$color_theme_01-01;
	}

	/* --- Field --- */
	.field {
		@extend .relative;
		padding-top:6px;

		label {
			color:$color_theme_01-01;
			font-size:$font_size_base;
			font-weight:600;

			@media screen and (max-width:768px)
			{
				font-size:16px;
			}

			&.field_label {
				padding:12px 0 6px;
			}
		}
	}

	/* --- Input text --- */
	input[type=tel],
	input[type=text],
	input[type=email],
	input[type=password],
	textarea {
		@extend .animate;
		padding:16px 64px 16px 24px;
		color:$color_theme_01-01;
		font-size:$font_size_base;
		background:#FFF;
		border:2px solid $color_theme_01-02;
		border-radius:6px;
		box-sizing:border-box;
	}

	textarea {
		min-height:120px;
	}

	/* --- Select --- */
	.field_select {
		background:#FFF;
		border:2px solid $color_theme_01-02;
		border-radius:6px;
		box-sizing:border-box;

		select {
			display:block;
			padding:16px 66px 16px 24px;
			width:100% !important; height:100% !important;
			color:$color_theme_01-01;
			font-size:$font_size_base;
			background:none;
		}

		.chevron {
			right:24px;
			margin-top:-9px;
			width:18px; height:18px;

			svg {
				width:18px; height:18px;
				fill:$color_theme_01-01;
			}
		}
	}

	/* --- Input checkbox & radio --- */
	.enquiry_contact-checkbox_wrapper {
		padding-top:12px;
	}

	.input_radio,
	.input_checkbox {

		@media screen and (max-width:768px)
		{
			margin-top:6px;
		}

		input[type=radio],
		input[type=checkbox] {
			width:32px; height:32px;
		}
		span {
			margin-right:12px;
			width:32px; height:32px;
			background:#FFF;
			border:2px solid $color_theme_01-02;
			border-radius:6px;
		}
		label {
			color:$color_theme_01-03;
			font-weight:400;
		}
	}
	.input_radio span:after,
	.input_checkbox span svg {
		margin:6px;
		width:16px; height:16px;
	}
	.input_radio span:after {
		background:#FFF;
	}
	.input_checkbox span svg {
		fill:$color_theme_01-01;
	}

	/* --- Input Submit --- */
	.wrapper_submit {
		display:flex;
		flex-direction:row;
		flex-wrap:wrap;
		flex-direction:row-reverse;
		align-items:center;
		justify-content:space-between;
		margin-top:24px;

		.input_submit {
			width:auto;
			background:none;
		}
	}

	/* --- Feedback --- */
	.field-feedback {
		@extend .relative;

		&:before {
			@extend .absolute;
			content:"";
			top:0; left:24px;
			width:0; height:0;
			border-left:6px solid transparent; border-right:6px solid transparent; border-bottom:6px solid $color_error;
		}

		span {
			display:block;
			margin-top:6px; padding:12px 24px;
			line-height:$line_height_small;
			font-size:$font_size_small;
			color:#FFF;
			background:$color_error;
			border-radius:6px;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE RECAPTCHA ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.g-recaptcha {
	display:none !important;
}
