/* ------------------------------------------------------------------------------------------------------------------------ */
/* CASE STUDIES ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.case-studies {
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CASE STUDIES (CAROUSEL) ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.case-studies_carousel {
	padding:96px;
	background:$color_theme_01-04;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding:48px;
	}
	@media screen and (max-width:768px)
	{
		position:relative;
		z-index:1;
		padding:24px;
	}
}
	.case-studies_carousel-lhc {
		justify-content:center;

		@media screen and (max-width:768px)
		{
			padding-bottom:24px;
		}
	}

	.case-studies_carousel-heading {
		padding-right:36px;
		color:$color_theme_01-01;
	}
		.case-studies_carousel-preheading {
			display:block;
			padding-bottom:6px;
			color:$color_theme_02-01;
			font-size:$font_size_base;
			font-weight:400;
		}

	.slick_case-studies {

		@media screen and (max-width:768px)
		{
			margin:0 -24px;
		}

		.slick-slide {

			@media screen and (max-width:768px)
			{
				padding:0 24px;
			}
		}
	}

	.case-studies_carousel-item_wrapper {
		display:flex !important;
		flex-direction:column;
		flex-wrap:nowrap;
		bottom:0;
		padding-bottom:24px;
	}

		.case-studies_carousel-item {
			@extend .relative;
			flex:1 1 auto;
			left:50%;
			width:calc(100% - 64px); max-width:480px;
			text-align:center;
			transform:translateX(-50%);

			@media screen and (max-width:768px)
			{
				width:calc(100% - 48px);
			}

			&:before,
			&:after {
				@extend .absolute;
				top:24px; bottom:24px;
				width:48px;
				background:rgba(#FFF, .48);

				@media screen and (min-width:769px)
				{
					content:"";
				}
			}
			&:before {
				left:-48px;
				border-radius:6px 0 0 6px;
			}
			&:after {
				right:-48px;
				border-radius:0 6px 6px 0;
			}
		}
			.case-studies_carousel-item_content {
				flex:1 1 auto;
				padding:32px 32px 12px;

				@media screen and (max-width:768px)
				{
					padding:0 0 12px;
				}
			}
			.case-studies_carousel-item_client-name {
				color:$color_theme_02-01;
				font-size:$font_size_base;
				font-weight:400;
			}
			.case-studies_carousel-item_client-image {
				margin:24px auto -56px;
				width:96px; height:96px;
				border-radius:100%;
				border:2px solid #FFF;
				overflow:hidden;

				@media screen and (max-width:768px)
				{
					margin:12px auto -48px;
				}
			}

		.case-studies_carousel-controls {
			padding-top:48px;

			@media screen and (max-width:768px)
			{
				display:flex;
				flex-direction:row;
				flex-wrap:nowrap;
				justify-content:space-between;
				position:absolute;
				left:48px; right:48px; bottom:35.5px;
				z-index:2;
				padding:0;
			}
		}
