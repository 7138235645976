/* ------------------------------------------------------------------------------------------------------------------------ */
/* CHARITIES -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.charities {
	padding:96px;
	text-align:center;

	@media screen and (max-width:1224px) and (min-width:1025px)
	{
		padding:96px 12px;
	}
	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding:48px 12px;
	}
	@media screen and (max-width:768px)
	{
		padding:24px;
	}

	.grid {
		margin-top:-12px;

		.column {

			@media screen and (max-width:1056px) and (min-width:769px)
			{
				width:50%;
			}
		}
	}
}

	.charities_heading {
		padding-bottom:48px;

		@media screen and (max-width:1024px)
		{
			padding-bottom:24px;
		}
	}

	.charities_item {
		margin-top:12px;
		line-height:$line_height_small;
	}
		.charities_item-logo {
			display:block;
			padding-bottom:24px;

			img {
				display:block;
				margin:0 auto;
				width:auto; height:64px;
			}
		}
		.charities_item-heading {
			line-height:1.2;
			color:$color_theme_01-01;
		}
		.charities_item-desc {
			padding-top:18px;

			@media screen and (max-width:768px)
			{
				padding-top:12px;
			}
		}
		.charities_item-btn {
			@extend .flex_column;
			flex:1 1 auto;
			justify-content:flex-end;
			align-items:center;
			padding-top:32px;

			@media screen and (max-width:768px)
			{
				padding-top:18px;
			}
		}
