/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn {
	@extend .animate;
	display:inline-block;
	background:none;
	cursor:pointer;

	&.btn_has-icon {
		display:inline-flex;
		flex-direction:row;
		flex-wrap:nowrap;
		align-items:center;

		svg {
			display:block;
			flex-shrink:0;
			padding-left:12px;
			width:18px; height:18px;

			&:first-child {
				padding-left:0; padding-right:12px;
			}
		}
	}

	&.btn_is-uppercase {
		font-size:16px;
		text-transform:uppercase;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (ROUND) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn_round,
.btn_round-ghost {
	font-weight:600;
	border-radius:999px;
}
.btn_round {
	padding:16px 28px;
}
.btn_round-ghost {
	padding:14px 26px;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (CLOSE) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.button_close {
	@extend .absolute;
	top:-12px; right:-12px;
	width:48px; height:48px;
	border-radius:6px;
	cursor:pointer;

	@media screen and (max-width:768px)
	{
		top:0; right:0;
		border-radius:0 0 0 6px;
	}

	svg {
		display:block;
		padding:15px;
		width:18px; height:18px;
		fill:#FFF;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (SLT) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.btn_slt.btn_has-icon {
	@extend .relative;
	padding:12px 24px;
	line-height:1.2;
	font-weight:600;
	text-align:left;
	border-left:4px solid #D2D1CD;

	&:before,
	&:after {
		@extend .absolute;
		content:"";
		top:2px; left:-10px;
		width:12px; height:12px;
		transform:rotate(45deg);
		border-top:4px solid #D2D1CD; border-left:4px solid #D2D1CD;
	}

	svg {
		width:32px; height:32px;
	}
}
