/* ------------------------------------------------------------------------------------------------------------------------ */
/* DISPLAY FLEX ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.flex_row,
.flex_column {
	display:flex;
	flex-wrap:nowrap;
}
.flex_row {
	flex-direction:row;
}
.flex_column {
	flex-direction:column;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CLEARFIX --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.clearfix {
	*zoom:1;

	&:before,
	&:after {
		content:"";
		display:table;
		line-height:0;
	}
	&:after {
		clear:both;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ALIGNMENT -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.left {
	float:left;
}
.right {
	float:right;
}
.center {
	margin:0 auto;
}

.text-left {
	text-align:left !important;
}
.text-right {
	text-align:right !important;
}
.text-center {
	text-align:center !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wBase {
	max-width:$site_width_base;
}
.wMedium {
	max-width:$site_width_medium;
}
.wSmall {
	max-width:$site_width_small;
}
.wXSmall {
	max-width:$site_width_xsmall;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BLEEDS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.bleed_top_24 {
	padding-top:24px;
}
.bleed_top_48 {
	padding-top:48px;
}
.bleed_top_96 {
	padding-top:96px;
}

.bleed_48 {
	padding:48px;
}
.bleed_96 {
	padding:96px;
}

@media screen and (max-width:1300px) and (min-width:769px)
{
	.bleed_top_48,
	.bleed_top_96 {
		padding-top:48px;
	}


	.bleed_48,
	.bleed_96 {
		padding:48px;
	}
}
@media screen and (max-width:768px)
{
	.bleed_top_48,
	.bleed_top_96 {
		padding-top:24px;
	}

	.bleed_48,
	.bleed_96 {
		padding:24px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MARGINS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_margin {
	margin:0 !important;
}
.reset_margin_top {
	margin-top:0 !important;
}
.reset_margin_right {
	margin-right:0 !important;
}
.reset_margin_bottom {
	margin-bottom:0 !important;
}
.reset_margin_left {
	margin-left:0 !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PADDINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_padding {
	padding:0 !important;
}
.reset_padding_top {
	padding-top:0 !important;
}
.reset_padding_right {
	padding-right:0 !important;
}
.reset_padding_bottom {
	padding-bottom:0 !important;
}
.reset_padding_left {
	padding-left:0 !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_border {
	border:0 solid !important;
}
.reset_border_top {
	border-top:0 solid !important;
}
.reset_border_right {
	border-right:0 solid !important;
}
.reset_border_bottom {
	border-bottom:0 solid !important;
}
.reset_border_left {
	border-left:0 solid !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* POSITIONS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.relative {
	position:relative;
	z-index:1;
}
.absolute {
	position:absolute;
	z-index:1;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* COLOURS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Black(ish) --- */
.color_01-01,
a.color_01-01, {
	color:$color_theme_01-01 !important;
}
a.color_01-01, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:$color_theme_01-01 !important;
			cursor:pointer;
		}
	}
}

/* --- White --- */
.color_white,
a.color_white, {
	color:#FFF !important;
}
a.color_02-03, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:#FFF !important;
			cursor:pointer;
		}
	}
}

/* --- Purple --- */
.color_02-01,
a.color_02-01, {
	color:$color_theme_02-01 !important;
}
a.color_02-01, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:darken($color_theme_02-01, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Red --- */
.color_02-02,
a.color_02-02, {
	color:$color_theme_02-02 !important;
}
a.color_02-02, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:darken($color_theme_02-02, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Green --- */
.color_02-03,
a.color_02-03, {
	color:$color_theme_02-03 !important;
}
a.color_02-03, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:darken($color_theme_02-03, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Orange --- */
.color_02-04,
a.color_02-04, {
	color:$color_theme_02-04 !important;
}
a.color_02-04, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:darken($color_theme_02-04, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Blue --- */
.color_02-05,
a.color_02-05, {
	color:$color_theme_02-05 !important;
}
a.color_02-05, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:darken($color_theme_02-05, 9%) !important;
			cursor:pointer;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BACKGROUNDS ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- White --- */
.bg_white {
	background:#FFF !important;
}

/* --- Black(ish) --- */
.bg_01-01,
a.bg_01-01,
button.bg_01-01, {
	background:$color_theme_01-01 !important;
}
a.bg_01-01,
button.bg_01-01, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			background:darken($color_theme_01-01, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Purple --- */
.bg_02-01,
a.bg_02-01,
button.bg_02-01, {
	background:$color_theme_02-01 !important;
}
a.bg_02-01,
button.bg_02-01, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			background:darken($color_theme_02-01, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Red --- */
.bg_02-02,
a.bg_02-02,
button.bg_02-02, {
	background:$color_theme_02-02 !important;
}
a.bg_02-02,
button.bg_02-02, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			background:darken($color_theme_02-02, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Green --- */
.bg_02-03,
a.bg_02-03,
button.bg_02-03, {
	background:$color_theme_02-03 !important;
}
a.bg_02-03,
button.bg_02-03, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			background:darken($color_theme_02-03, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Orange --- */
.bg_02-04,
a.bg_02-04,
button.bg_02-04, {
	background:$color_theme_02-04 !important;
}
a.bg_02-04,
button.bg_02-04, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			background:darken($color_theme_02-04, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Blue --- */
.bg_02-05,
a.bg_02-05,
button.bg_02-05, {
	background:$color_theme_02-05 !important;
}
a.bg_02-05,
button.bg_02-05, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			background:darken($color_theme_02-05, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Twitter --- */
.bg_twitter,
a.bg_twitter,
button.bg_twitter, {
	background:$color_twitter !important;
}
a.bg_twitter,
button.bg_twitter, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			background:darken($color_twitter, 9%) !important;
			cursor:pointer;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HOVERS (COLOR) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Purple --- */
.hover_color_02-01 {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:$color_theme_02-01 !important;
		}
	}
}

/* --- Red --- */
.hover_color_02-02 {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:$color_theme_02-02 !important;
		}
	}
}

/* --- Green --- */
.hover_color_02-03 {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:$color_theme_02-03 !important;
		}
	}
}

/* --- Orange --- */
.hover_color_02-04 {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:$color_theme_02-04 !important;
		}
	}
}

/* --- Blue --- */
.hover_color_02-05 {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:$color_theme_02-05 !important;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FILLS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Purple --- */
.fill_02-01 svg {
	@extend .animate;
	fill:$color_theme_02-01 !important;
}
a.fill_02-01,
button.fill_02-01, {

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			fill:darken($color_theme_02-01, 9%) !important;
		}
	}
}

/* --- Red --- */
.fill_02-02 svg {
	@extend .animate;
	fill:$color_theme_02-02 !important;
}
a.fill_02-02,
button.fill_02-02, {

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			fill:darken($color_theme_02-02, 9%) !important;
		}
	}
}

/* --- Green --- */
.fill_02-03 svg {
	@extend .animate;
	fill:$color_theme_02-03 !important;
}
a.fill_02-03,
button.fill_02-03, {

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			fill:darken($color_theme_02-03, 9%) !important;
		}
	}
}

/* --- Orange --- */
.fill_02-04 svg {
	@extend .animate;
	fill:$color_theme_02-04 !important;
}
a.fill_02-04,
button.fill_02-04, {

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			fill:darken($color_theme_02-04, 9%) !important;
		}
	}
}

/* --- Blue --- */
.fill_02-05 svg {
	@extend .animate;
	fill:$color_theme_02-05 !important;
}
a.fill_02-05,
button.fill_02-05, {

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			fill:darken($color_theme_02-05, 9%) !important;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HOVERS (FILL) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Purple --- */
.hover_fill_02-01 {

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			fill:$color_theme_02-01 !important;
		}
	}
}

/* --- Red --- */
.hover_fill_02-02 {

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			fill:$color_theme_02-02 !important;
		}
	}
}

/* --- Green --- */
.hover_fill_02-03 {

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			fill:$color_theme_02-03 !important;
		}
	}
}

/* --- Orange --- */
.hover_fill_02-04 {

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			fill:$color_theme_02-04 !important;
		}
	}
}

/* --- Blue --- */
.hover_fill_02-05 {

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			fill:$color_theme_02-05 !important;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Black(ish) --- */
.border_01-01,
a.border_01-01,
button.border_01-01, {
	color:$color_theme_01-01 !important;
	border:2px solid $color_theme_01-01 !important;
}
a.border_01-01,
button.border_01-01, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:darken($color_theme_01-01, 9%) !important;
			border:2px solid darken($color_theme_01-01, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Purple --- */
.border_02-01,
a.border_02-01,
button.border_02-01, {
	color:$color_theme_02-01 !important;
	border:2px solid $color_theme_02-01 !important;
}
a.border_02-01,
button.border_02-01, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:darken($color_theme_02-01, 9%) !important;
			border:2px solid darken($color_theme_02-01, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Red --- */
.border_02-02,
a.border_02-02,
button.border_02-02, {
	color:$color_theme_02-02 !important;
	border:2px solid $color_theme_02-02 !important;
}
a.border_02-02,
button.border_02-02, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:darken($color_theme_02-02, 9%) !important;
			border:2px solid darken($color_theme_02-02, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Green --- */
.border_02-03,
a.border_02-03,
button.border_02-03, {
	color:$color_theme_02-03 !important;
	border:2px solid $color_theme_02-03 !important;
}
a.border_02-03,
button.border_02-03, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:darken($color_theme_02-03, 9%) !important;
			border:2px solid darken($color_theme_02-03, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Orange --- */
.border_02-04,
a.border_02-04,
button.border_02-04, {
	color:$color_theme_02-04 !important;
	border:2px solid $color_theme_02-04 !important;
}
a.border_02-04,
button.border_02-04, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:darken($color_theme_02-04, 9%) !important;
			border:2px solid darken($color_theme_02-04, 9%) !important;
			cursor:pointer;
		}
	}
}

/* --- Blue --- */
.border_02-05,
a.border_02-05,
button.border_02-05, {
	color:$color_theme_02-05 !important;
	border:2px solid $color_theme_02-05 !important;
}
a.border_02-05,
button.border_02-05, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:darken($color_theme_02-05, 9%) !important;
			border:2px solid darken($color_theme_02-05, 9%) !important;
			cursor:pointer;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL SIZE -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full_size {
	position:absolute;
	top:0; left:0; right:0; bottom:0;
	z-index:2;
	width:100%; height:100%;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL WIDTH ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full_width {
	display:block;
	width:100%; height:auto;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANIMATE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.animate {
	transition-duration:300ms;
	transition-timing-function:ease-in-out;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* RESPONSIVENESS --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.only_desktop {
	display:none;

	@media screen and (min-width:769px)
	{
		display:block;
	}
}

.only_mobile {
	display:none;

	@media screen and (max-width:768px)
	{
		display:block;
	}
}

.visuallyHidden {
	display:none !important;
}
